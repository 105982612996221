/* src/components/styling/NoteView.css */

/* Full-Screen Background Styling */
.note-view-section {
  padding: 40px 20px; /* Increased padding for better spacing */
  min-height: 100vh; /* Full screen */
  background: linear-gradient(135deg, #f7f9fc, #e2e8f0); /* Subtle gradient background */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Modern font */
}

/* Container Styling */
.noteview-container {
  max-width: 1200px;
  margin: 0 auto;
}

/* Welcome Section Styling */
.welcome-section {
  text-align: center;
  margin-bottom: 40px; /* Increased margin */
}

.welcome-section h2 {
  font-size: 2.5rem; /* Larger font size */
  margin-bottom: 10px;
  color: #2d3748; /* Darker color for better contrast */
}

.welcome-section p {
  font-size: 1.2rem;
  color: #4a5568;
}

/* Recent Notes Section */
.recent-notes-section {
  margin-top: 20px;
}

.recent-notes-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

/* Note Card Styling */
.note-card {
  border: 1px solid #d1d5db; /* Light border similar to Google Keep */
  border-radius: 12px; /* Smooth edges */
  width: 280px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.3s ease, border-color 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #333333; /* Dark text for readability */
  overflow: hidden; /* Ensure content doesn't overflow */
}

.note-card:hover {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
  transform: translateY(-4px);
  border-color: #a0aec0; /* Slightly darker border on hover */
}

/* Ensure text is readable against background colors */
.note-card h4 {
  font-size: 1.4rem;
  margin-bottom: 12px;
  color: #1a202c; /* Darker shade */
  font-weight: 600; /* Bold for titles */
}

.note-card p {
  font-size: 1rem;
  color: #333333; /* Darker text color for better contrast */
  margin-bottom: 16px;
  flex-grow: 1;
  overflow: hidden;
  line-height: 1.5;
}

.note-date {
  font-size: 0.9rem;
  color: #555555; /* Slightly lighter for secondary information */
  align-self: flex-end;
  font-style: italic;
}

/* Loading Indicator Styling */
.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}

.dot-spinner {
  display: flex;
  gap: 10px;
}

.dot {
  width: 14px;
  height: 14px;
  background-color: #4a5568;
  border-radius: 50%;
  animation: dot-blink 0.8s infinite alternate;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dot-blink {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}

/* No Recent Notes Styling */
.no-recent-notes {
  text-align: center;
  margin-top: 50px;
}

.no-recent-notes p {
  font-size: 1.1rem;
  color: #4a5568;
}

/* Responsive Design */
@media (max-width: 768px) {
  .note-card {
    width: 100%;
  }

  .welcome-section h2 {
    font-size: 2rem;
  }

  .welcome-section p {
    font-size: 1rem;
  }
}
