@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* General Container for Landing Page */
.landing-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    color: #333;
  }
  
  /* Hero Section Styling */
  .hero-section {
    background: linear-gradient(to right, #6dd5ed, #2193b0);
    color: white;
    padding: 60px 20px;
    border-radius: 20px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
  }
  
  .hero-section h1 {
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .hero-section p {
    font-size: 18px;
    margin-bottom: 30px;
    line-height: 1.5;
  }
  
  .hero-section .cta-buttons {
    display: flex;
    justify-content: center;
    grid-gap: 20px;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .hero-section .cta-button {
    background-color: #ffffff;
    color: #2193b0;
    padding: 15px 30px;
    border-radius: 30px;
    border: none;
    cursor: pointer;
    font-weight: 600;
    transition: all 0.3s ease;
    box-shadow: 0px 6px 15px rgba(255, 255, 255, 0.3);
  }
  
  .hero-section .cta-button:hover {
    transform: translateY(-4px);
    box-shadow: 0px 10px 20px rgba(255, 255, 255, 0.4);
  }
  
  /* Features Section Styling */
  .features-section {
    margin-top: 50px;
    padding: 40px 20px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  }
  
  .features-section h2 {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 30px;
  }
  
  .features-section .features-list {
    display: flex;
    justify-content: space-between;
    grid-gap: 20px;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .features-section .feature {
    background-color: #f8f9fa;
    flex: 1 1;
    min-width: 280px;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .features-section .feature-icon {
    font-size: 40px;
    margin-bottom: 15px;
    color: #2193b0;
  }
  
  .features-section .feature h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .features-section .feature p {
    font-size: 16px;
    color: #555;
  }
  
  /* Call to Action Section */
  .cta-section {
    margin-top: 50px;
    padding: 50px 20px;
    background: linear-gradient(to right, #2193b0, #6dd5ed);
    color: white;
    border-radius: 20px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  }
  
  .cta-section h2 {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .cta-section p {
    font-size: 18px;
    margin-bottom: 30px;
  }
  
  .cta-section .cta-button {
    background-color: #ffffff;
    color: #2193b0;
    padding: 15px 30px;
    border-radius: 30px;
    border: none;
    cursor: pointer;
    font-weight: 600;
    transition: all 0.3s ease;
    box-shadow: 0px 6px 15px rgba(255, 255, 255, 0.3);
  }
  
  .cta-section .cta-button:hover {
    transform: translateY(-4px);
    box-shadow: 0px 10px 20px rgba(255, 255, 255, 0.4);
  }
  
  /* Footer Section Styling */
  .footer {
    margin-top: 50px;
    padding: 20px;
    background-color: #333;
    color: white;
    border-radius: 20px;
    text-align: center;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  }
  
  .footer a {
    color: #6dd5ed;
    text-decoration: none;
    font-weight: 600;
  }
  
  .footer a:hover {
    text-decoration: underline;
  }
  
  .footer p {
    font-size: 16px;
    margin: 5px 0;
  }
  
  .footer .love {
    color: #ff6b6b;
    font-weight: bold;
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 768px) {
    .features-section .features-list {
      flex-direction: column;
      grid-gap: 20px;
      gap: 20px;
    }
  
    .cta-buttons {
      flex-direction: column;
      grid-gap: 15px;
      gap: 15px;
    }
  
    .hero-section h1 {
      font-size: 36px;
    }
  
    .hero-section p,
    .cta-section p {
      font-size: 16px;
    }
  }
  
/* General Container for Profile Page */
.profile-page {
    max-width: 800px;  /* More compact compared to homepage */
    margin: 0 auto;
    padding: 40px 20px;
    font-family: 'Poppins', sans-serif;
    color: #333;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  }
  
  /* Profile Title Styling */
  .profile-title {
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    color: #2193b0;
    margin-bottom: 20px;
  }
  
  /* Profile Container Styling */
  .profile-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    grid-gap: 30px;
    gap: 30px;
  }
  
  /* Profile Picture Section */
  .profile-picture-section {
    text-align: center;
  }
  
  .profile-picture-container {
    margin: 0 auto 20px;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .profile-picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .default-profile-picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #f8f9fa;
    color: #555;
    font-weight: 600;
    font-size: 14px;
  }
  
  .profile-picture-input {
    margin-top: 15px;
    font-size: 16px;
  }
  
  /* User Information Section */
  .user-info-section {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
  }
  
  .input-label {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;
    color: #2193b0;
  }
  
  .input-field {
    padding: 15px;
    border-radius: 15px;
    border: 1px solid #ddd;
    font-size: 16px;
    color: #333;
    background-color: #f8f9fa;
    transition: all 0.3s ease;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .input-field:focus {
    border-color: #2193b0;
    outline: none;
    box-shadow: 0px 5px 15px rgba(33, 147, 176, 0.2);
  }
  
  .input-field.disabled {
    background-color: #e9ecef;
    color: #888;
  }
  
  /* Action Buttons Styling */
  .action-buttons {
    display: flex;
    justify-content: center;
    grid-gap: 20px;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .save-button,
  .logout-button {
    background: #ffffff;
    color: #2193b0;
    padding: 15px 30px;
    border-radius: 30px;
    border: none;
    cursor: pointer;
    font-weight: 600;
    transition: all 0.3s ease;
    box-shadow: 0px 6px 15px rgba(255, 255, 255, 0.3);
  }
  
  .save-button:hover,
  .logout-button:hover {
    transform: translateY(-4px);
    box-shadow: 0px 10px 20px rgba(255, 255, 255, 0.4);
  }
  
  .save-button {
    background: linear-gradient(to right, #6dd5ed, #2193b0);
    color: white;
  }
  
  .logout-button {
    background-color: #ff6b6b;
    color: white;
  }
  
  .logout-button:hover {
    transform: translateY(-4px);
    box-shadow: 0px 10px 20px rgba(255, 107, 107, 0.4);
  }
  
  /* Profile Page Mobile Responsiveness */
  @media (max-width: 768px) {
    .profile-page {
      padding: 30px 15px;
    }
  
    .profile-title {
      font-size: 28px;
    }
  
    .input-field {
      font-size: 14px;
      padding: 12px;
    }
  
    .save-button,
    .logout-button {
      padding: 12px 25px;
    }
  
    .profile-picture-container {
      width: 100px;
      height: 100px;
    }
  }

  /* Profile Page Styling */
.profile-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 20px;
    font-family: 'Poppins', sans-serif;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  }
  
  .profile-title {
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Back Button Styling */
  .back-button {
    background: #6dd5ed;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 30px;
    cursor: pointer;
    font-weight: 600;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    transition: all 0.3s ease;
  }
  
  .back-button:hover {
    background: #2193b0;
    transform: translateY(-2px);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  /* Feedback Message Styling */
  .feedback-message {
    background-color: #f0f0f0;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 10px;
    font-size: 14px;
    color: #555;
    text-align: center;
  }
  
  
/* frontend/src/pages/ForgotPasswordPage.css */

/* Import Poppins Font */

/* Global Styles */
body {
  font-family: 'Poppins', sans-serif;
  background-color: #f1fcff;
  margin: 0;
  padding: 0;
}

/* Forgot Password Page Styles */
.forgot-password-page {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
}

.forgot-password-container {
  background-color: #ffffff;
  padding: 40px 30px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.forgot-password-title {
  font-size: 28px;
  color: #2193b0;
  margin-bottom: 30px;
}

.forgot-password-form {
  display: flex;
  flex-direction: column;
}

.forgot-password-input {
  padding: 14px 18px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 20px;
  transition: border-color 0.3s;
}

.forgot-password-input:focus {
  border-color: #2193b0;
  outline: none;
}

.forgot-password-button {
  padding: 14px 18px;
  font-size: 16px;
  background-color: #2193b0;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s;
}

.forgot-password-button:hover {
  background-color: #176b85;
}

.forgot-password-button:disabled {
  background-color: #a0a0a0;
  cursor: not-allowed;
}

.forgot-password-message {
  margin-top: 20px;
  font-size: 14px;
  text-align: center;
}

.forgot-password-message.success {
  color: #4caf50;
}

.forgot-password-message.error {
  color: #f44336;
}

.forgot-password-links {
  margin-top: 30px;
}

.forgot-password-links a {
  color: #2193b0;
  text-decoration: none;
  font-size: 14px;
}

.forgot-password-links a:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 480px) {
  .forgot-password-container {
    padding: 30px 20px;
  }

  .forgot-password-title {
    font-size: 24px;
  }

  .forgot-password-input,
  .forgot-password-button {
    font-size: 14px;
    padding: 12px 16px;
  }

  .forgot-password-message {
    font-size: 12px;
  }
}

/* General Wrapper to Center Content Vertically and Horizontally */
.auth-page-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Make the wrapper cover the entire viewport height */
  background: linear-gradient(to bottom, #e0f7fa, #ffffff); /* Background to match design philosophy */
}

/* General Container for Auth Page */
.auth-page {
  max-width: 400px;
  width: 100%;
  padding: 40px 20px;
  font-family: 'Poppins', sans-serif;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Header Styling */
.auth-page h2 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #2193b0;
}

/* Input Field Styling */
.auth-page .input-field {
  width: 100%;
  padding: 15px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 10px;
  font-size: 16px;
  box-sizing: border-box;
  transition: all 0.3s ease;
}

.auth-page .input-field:focus {
  border-color: #2193b0;
  box-shadow: 0 0 8px rgba(109, 213, 237, 0.4);
}

/* Button Styling */
.auth-page .auth-button {
  background-color: #2193b0;
  color: white;
  border: none;
  padding: 15px 30px;
  border-radius: 30px;
  cursor: pointer;
  font-weight: 600;
  width: 100%;
  transition: all 0.3s ease;
  box-shadow: 0px 6px 15px rgba(33, 147, 176, 0.3);
  margin-top: 20px;
}

.auth-page .auth-button:hover {
  background-color: #6dd5ed;
  box-shadow: 0px 10px 20px rgba(109, 213, 237, 0.4);
}

/* Alternate Action Section */
.auth-page .alternate-action {
  margin-top: 20px;
  font-size: 14px;
}

.auth-page .alternate-action a {
  color: #2193b0;
  text-decoration: none;
  font-weight: 600;
}

.auth-page .alternate-action a:hover {
  text-decoration: underline;
}

/* Forgot Password Button Styling */
.forgot-password-button {
  background: none;
  border: none;
  color: #2193b0;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 10px;
}

.forgot-password-button:hover {
  color: #6dd5ed;
}

/* Error Message Styling */
.auth-page .error-message {
  color: #ff6b6b;
  font-size: 14px;
  margin-top: 10px;
}

/* Success Message Styling */
.auth-page .success-message {
  color: #28a745;
  font-size: 14px;
  margin-top: 10px;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .auth-page {
    padding: 30px 15px;
  }

  .auth-page h2 {
    font-size: 28px;
  }

  .auth-page .auth-button {
    padding: 12px 25px;
  }
}

/* src/pages/styling/NotesPage.css */

/* Import Poppins Font */

/* Global Box-Sizing Reset */
*, *::before, *::after {
    box-sizing: border-box;
}

/* Global Styles for the Application */
body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    color: #4D4D4D;
    background-color: #FFFFFF;
    overflow-x: hidden;
}

/* Utility Classes */
.hidden {
    display: none;
}

.visible {
    display: block;
}

/* Header Styling */
.app-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #f1fcff;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1500;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    flex-wrap: wrap;
    grid-gap: 8px;
    gap: 8px;
}

/* Header Left Section */
.header-left {
    display: flex;
    align-items: center;
    flex: 1 1;
    min-width: 0;
}

/* Header Title */
.app-header h1 {
    text-align: left;
    color: #2193b0;
    font-size: 24px;
    font-weight: 700;
    margin: 0;
    flex-shrink: 0;
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 50px;
}

/* Header Center Section (Buttons) */
.header-center {
    display: none;
    align-items: center;
    justify-content: center;
    flex: 1 1;
    min-width: 0;
    grid-gap: 10px;
    gap: 10px;
}

/* Show Header Buttons When a Note is Open */
.app-header.note-open .header-center {
    display: flex;
}

/* Header Buttons Styling */
.header-button {
    background-color: #FFFFFF;
    color: #2193b0;
    border: 1px solid #2193b0;
    padding: 6px 10px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 12px;
    transition: background-color 0.2s, color 0.2s;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    white-space: nowrap;
}

.header-button:hover {
    background-color: #2193b0;
    color: #FFFFFF;
}

/* AI Notes Button */
.ai-notes-button {
    background-color: #FF5722;
    color: #FFFFFF;
    border: none;
    padding: 10px 16px;
    border-radius: 25px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    transition: background-color 0.3s, transform 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 8px;
    gap: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
    animation: pulse 3s infinite;
}

.ai-notes-button:hover {
    background-color: #E64A19;
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.ai-notes-button:active {
    background-color: #D84315;
    transform: translateY(0);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.ai-notes-icon {
    width: 20px;
    height: 20px;
    transition: transform 0.3s;
}

.ai-notes-text {
    font-size: 14px;
    color: #FFFFFF;
    font-weight: 600;
}

/* Upload Image Container */
.upload-image-container {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.upload-image-container label.header-button {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 6px 10px;
}

/* Profile Section */
.profile-section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    flex: 1 1;
    min-width: 0;
    margin-left: auto;
}

/* Profile Icon */
.profile-icon {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #2193b0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s;
}

.profile-icon:hover {
    background-color: #1a78a0;
}

.profile-picture {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.profile-placeholder {
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
}

/* Profile Dropdown Menu */
.profile-dropdown {
    position: absolute;
    top: 45px;
    right: 0;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    z-index: 2000;
    min-width: 140px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.profile-dropdown ul {
    list-style: none;
    margin: 0;
    padding: 8px 0;
}

.profile-dropdown li {
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.profile-dropdown li:hover {
    background-color: #f1f1f1;
}

/* Sidebar Toggle Button (Hamburger Icon) */
.sidebar-toggle-button {
    position: fixed;
    top: 12px;
    left: 12px;
    background-color: #ffffff;
    border: none;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    font-size: 20px;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 1600;
    transition: transform 0.3s, box-shadow 0.3s;
}

.sidebar-toggle-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* Adjust Main Container to Account for Fixed Header and Breadcrumbs */
.notes-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
    background-color: #FFFFFF;
    padding-top: 60px;
    overflow-x: hidden;
}

/* Breadcrumbs Wrapper */
.breadcrumbs-wrapper {
    position: fixed;
    top: 60px;
    left: 250px;
    right: 0;
    z-index: 999;
    padding: 12px 0;
    background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
}

/* Tablet Adjustments */
@media (max-width: 1024px) and (min-width: 769px) {
    .breadcrumbs-wrapper {
        left: 200px;
        width: calc(100% - 200px);
    }
}

/* No Note Selected */
.no-note-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100vh - 60px);
    padding: 15px;
    box-sizing: border-box;
}

.no-note-selected {
    text-align: center;
    color: #555555;
}

.no-note-selected p {
    font-size: 16px;
    margin-top: 10px;
}

.no-note-selected h2 {
    font-size: 22px;
    margin-bottom: 8px;
    margin-top: -20px;
    color: #2d3748;
}

/* Note View Content Area */
.note-view-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    background-color: #FFFFFF;
    padding: 15px;
    overflow-y: auto;
}

/* Editor Content Area */
.editor-content {
    width: 100%;
    max-width: 750px;
    flex-grow: 1;
    outline: none;
    font-size: 16px;
    line-height: 1.6;
    color: #333333;
    white-space: pre-wrap;
    word-wrap: break-word;
    position: relative;
    overflow-y: auto;
}

/* Toolbar Styling */
.toolbar {
    width: 100%;
    max-width: 750px;
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    padding: 8px;
    background-color: #f8f9fa;
    border-radius: 8px;
    flex-wrap: wrap;
    margin-bottom: 8px;
    box-sizing: border-box;
}

.toolbar button,
.toolbar select {
    background-color: #FFFFFF;
    color: #4D4D4D;
    border: 1px solid #ddd;
    padding: 6px 10px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 12px;
    transition: background-color 0.2s, transform 0.1s, color 0.2s;
    flex: 0 0 auto;
}

.toolbar button:hover,
.toolbar select:hover {
    background-color: #f0f0f0;
}

.toolbar button.active {
    background-color: #2193b0;
    color: #FFFFFF;
    border-color: #2193b0;
}

.toolbar .text-color-select {
    padding: 6px 10px;
    border-radius: 6px;
    border: 1px solid #ddd;
    background-color: #ffffff;
    color: #4D4D4D;
    cursor: pointer;
}

.toolbar .text-color-select:hover {
    border-color: #2193b0;
}

.toolbar .image-upload-options {
    display: flex;
    grid-gap: 8px;
    gap: 8px;
}

.toolbar .image-upload-label {
    background-color: #FFFFFF;
    color: #2193b0;
    border: 1px solid #2193b0;
    padding: 6px 10px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 12px;
    transition: background-color 0.2s, color 0.2s;
}

.toolbar .image-upload-label:hover {
    background-color: #2193b0;
    color: #FFFFFF;
}

/* Editor Buttons (Close Note) */
.editor-buttons {
    width: 100%;
    max-width: 750px;
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
}

.close-button {
    padding: 8px 16px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-weight: 600;
    background-color: #f8f9fa;
    color: #333333;
    transition: background-color 0.3s, transform 0.3s;
}

.close-button:hover {
    background-color: #e9ecef;
    transform: translateY(-2px);
}

/* Modal Styling */
.ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.5) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

/* Actual Modal Content */
.modal-content {
    background-color: #ffffff;
    padding: 25px;
    border-radius: 20px;
    width: 90%;
    max-width: 380px;
    position: relative;
    transform: scale(1);
    animation: modalFadeIn 0.3s ease-in-out;
}

@keyframes modalFadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

.modal-content h2 {
    margin-top: 0;
    color: #2193b0;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 16px;
}

.folder-modal-content {
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    gap: 12px;
}

.folder-name-input {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 14px;
    transition: border-color 0.3s;
    width: 100%;
    box-sizing: border-box;
}

.folder-name-input:focus {
    border-color: #2193b0;
    outline: none;
}

.modal-buttons {
    display: flex;
    justify-content: flex-end;
    grid-gap: 8px;
    gap: 8px;
    margin-top: 16px;
}

/* Editor Placeholder */
.editor-content.is-empty::before {
    content: attr(data-placeholder);
    color: #aaa;
    position: absolute;
    pointer-events: none;
    left: 15px;
    top: 15px;
    font-size: 16px;
    line-height: 1.6;
}

/* Placeholder for Title Input */
.title-input.is-empty::before {
    content: attr(data-placeholder);
    color: #aaa;
    position: absolute;
    pointer-events: none;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    line-height: 1.2;
}

/* Responsive Design */

/* Mobile Styles */
@media (max-width: 768px) {
    /* Adjust Main Container Padding for Small Screens */
    .notes-container {
        padding-top: 80px;
    }

    .no-note-wrapper {
        height: calc(100vh - 80px);
    }

    /* Show Hamburger Button */
    .sidebar-toggle-button {
        display: flex;
    }

    /* Prevent Header Title Overlap */
    .app-header {
        flex-wrap: nowrap;
    }

    .app-header h1 {
        flex: 1 1;
        margin-right: 50px;
    }

    /* Adjust AI Notes Button on Mobile */
    .ai-notes-button {
        padding: 8px 12px;
        font-size: 12px;
        grid-gap: 6px;
        gap: 6px;
    }

    .ai-notes-text {
        display: none;
    }

    /* Adjust Image Insertion Button on Mobile */
    .upload-image-container label.header-button {
        padding: 6px 8px;
        font-size: 12px;
        grid-gap: 4px;
        gap: 4px;
    }

    .upload-image-container label.header-button span {
        display: none;
    }

    /* Adjust Breadcrumbs for Mobile */
    .breadcrumbs-wrapper {
        left: 0;
        top: 50px;
        padding: 8px 0;
        width: 100%;
    }

    .breadcrumbs {
        padding: 0 16px;
        margin: 10px auto;
    }

    .breadcrumbs ul {
        grid-gap: 10px;
        gap: 10px;
    }

    .breadcrumbs li {
        font-size: 14px;
    }

    .breadcrumbs li a {
        font-weight: 400;
    }

    .breadcrumbs li::after {
        font-size: 16px;
        margin-left: 8px;
    }

    .breadcrumbs li span {
        font-size: 14px;
    }

    /* Further reduce padding and margins */
    .recent-notes-section {
        margin-top: 8px;
    }

    .recent-notes-grid {
        grid-gap: 8px;
        gap: 8px;
    }

    .welcome-section h2 {
        font-size: 20px;
        margin-top: 10px;
        margin-bottom: 6px;
    }

    .note-card {
        padding: 10px;
    }

    /* Adjust Toolbar */
    .toolbar {
        padding: 6px;
        grid-gap: 6px;
        gap: 6px;
    }

    .toolbar button,
    .toolbar select {
        padding: 5px 8px;
        font-size: 12px;
    }

    /* Adjust Editor Buttons */
    .editor-buttons {
        flex-direction: column;
        align-items: flex-end;
        max-width: 750px;
    }

    .close-button {
        width: 100%;
        padding: 8px 14px;
    }
}

/* Very Small Screens */
@media (max-width: 480px) {
    /* Further Adjust Main Container Padding */
    .notes-container {
        padding-top: 70px;
    }

    .no-note-wrapper {
        height: calc(100vh - 70px);
    }

    /* Adjust Header Title */
    .app-header h1 {
        margin-right: 40px;
        font-size: 20px;
    }

    /* Adjust AI Notes Button */
    .ai-notes-button {
        padding: 6px 10px;
        font-size: 11px;
        grid-gap: 4px;
        gap: 4px;
    }

    /* Adjust Breadcrumbs */
    .breadcrumbs-wrapper {
        padding: 6px 0;
    }

    .breadcrumbs {
        padding: 0 12px;
        margin: 8px auto;
    }

    .breadcrumbs ul {
        grid-gap: 8px;
        gap: 8px;
    }

    .breadcrumbs li,
    .breadcrumbs li a,
    .breadcrumbs li span {
        font-size: 13px;
    }

    .breadcrumbs li::after {
        font-size: 14px;
        margin-left: 6px;
    }
}

/* Tablet Styles */
@media (min-width: 769px) and (max-width: 1024px) {
    .breadcrumbs-wrapper {
        left: 200px;
        width: calc(100% - 200px);
    }

    .app-header h1 {
        margin-right: 50px;
    }

    .upload-image-container label.header-button span {
        display: inline;
    }
}

/* Offline Alert Styling */
.offline-alert {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #c53d3d;
    color: white;
    text-align: center;
    padding: 8px;
    z-index: 2000;
    font-weight: bold;
}

.offline-alert p {
    margin: 0;
    font-size: 14px;
}

/* Save Indicator Styling */
.save-indicator {
    position: fixed;
    bottom: 16px;
    right: 16px;
    background-color: #4caf50;
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    z-index: 2000;
    font-size: 10px;
    transition: background-color 0.3s, opacity 0.3s;
    opacity: 1;
}

.save-indicator.saving {
    background-color: #ff9800;
}

/* Loading Indicators */
.notes-loading-indicator,
.folders-loading-indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    font-size: 14px;
    color: #555;
}

/* Load More Button */
.load-more-button {
    padding: 8px 16px;
    margin: 16px auto;
    display: block;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.load-more-button:hover {
    background-color: #0056b3;
}

/* Disabled Save Button */
.save-button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
}

/* Cancel Button */
.cancel-button {
    padding: 8px 16px;
    margin-left: 8px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.cancel-button:hover {
    background-color: #c82333;
}

/* Error Message Styling */
.error-message {
    text-align: center;
    margin-top: 40px;
    color: #e53e3e;
    font-size: 14px;
}

/* Note View Specific Styles */
.note-view-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    background-color: #FFFFFF;
    padding: 15px;
    overflow-y: auto;
}

/* Note Content Area */
.note-view-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    background-color: #FFFFFF;
    padding: 15px;
    overflow-y: auto;
}

/* Note Editor Specific Styles */
.note-editor {
    width: 100%;
    max-width: 750px;
    margin: 0 auto;
    padding: 10px;
}

/* Recent Notes Presentation */
.recent-notes-section {
    margin-top: 10px;
}

.recent-notes-grid {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 12px;
    gap: 12px;
    justify-content: center;
}

/* Note Cards */
.note-card {
    margin: 0;
    padding: 12px;
    border: 1px solid #d1d5db;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.3s;
}

.note-card:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    transform: translateY(-4px);
}

/* Recent Notes Title */
.welcome-section h2 {
    font-size: 22px;
    margin-bottom: 8px;
    margin-top: -20px;
    color: #2d3748;
}

/* Pulse Animation */
@keyframes pulse {
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(255, 87, 34, 0.7);
    }
    70% {
        transform: scale(1.05);
        box-shadow: 0 0 0 10px rgba(255, 87, 34, 0);
    }
    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(255, 87, 34, 0);
    }
}

/* Footer */
.footer {
    margin-top: 0;
}

/* src/components/styling/NoteEditor.css */

/* CSS Variables for Maintainability */
:root {
  --color-primary: #3b82f6;
  --color-primary-hover: #2563eb;
  --color-placeholder: #9ca3af;
  --color-text: #1f2937;
  --color-muted: #6b7280;
  --color-bg: #ffffff;
  --font-base: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  --font-mono: 'Source Code Pro', monospace;
  --max-width-desktop: 1200px;
  --max-width-tablet: 900px;
  --max-width-mobile: 100%;
  --sidebar-width-tablet: 250px; /* Adjust based on your sidebar's width */
  --padding-desktop: 0px;
  --padding-tablet: 20px;
  --padding-mobile: 0px;
  --font-size-base: 1.01rem; /* 18px */
  --font-size-mobile: 1rem; /* 16px */
  --font-size-tablet: 1.0625rem; /* 17px */
}

/* Wrapper to fill the full viewport height and center content */
.note-editor-wrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: flex-start; /* Align items to the top */
  padding-top: 0px; /* Top padding for spacing */
  box-sizing: border-box;
  background-color: var(--color-bg); /* White background to blend with content */
  margin-left: 0; /* Default margin */
  transition: margin-left 0.3s ease; /* Smooth transition for margin changes */
  margin-left: 140px;

}

/* Adjust wrapper to account for sidebar on tablets */
@media (min-width: 769px) and (max-width: 1024px) {
  .note-editor-wrapper {
    margin-left: var(--sidebar-width-tablet); /* Prevent overlapping with sidebar */
    padding-left: var(--padding-tablet);
    padding-right: var(--padding-tablet);
    padding-top: 70px;
  }
}

/* Container to constrain the editor's width */
.note-editor-container {
  width: 100%;
  max-width: var(--max-width-desktop); /* Maximum width of the container */
  padding: 0 var(--padding-desktop); /* Horizontal padding */
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: center; /* Center the content within the container */
}

/* Message when no note is selected */
.no-note-selected {
  text-align: center;
  color: var(--color-muted);
  padding: 3.75rem 1.25rem; /* 60px 20px */
  font-size: 1.25rem; /* 20px */
}

/* Note Content */
.note-content {
  width: 100%;
  display: flex;
  justify-content: center; /* Center the editor content */
}

/* Editor Container */
.editor-container {
  width: 100%;
  max-width: 800px; /* Set a maximum width for the editor */
  position: relative;
}

/* Editor Content */
.editor-content {
  width: 100%;
  max-width: 800px; /* Ensure the content doesn't exceed the container */
  margin: 0 auto; /* Center the editor content */
  min-height: 600px;
  font-size: var(--font-size-base); /* 18px */
  line-height: 1.8;
  color: var(--color-text);
  font-family: var(--font-base);
  overflow-wrap: break-word; /* Replaced deprecated word-wrap */
  padding: 20px; /* Added padding for better spacing */
  box-sizing: border-box;
  position: relative;
}

/* Placeholder Styling for Paragraphs */
.editor-content p.is-empty {
  position: relative;
}

.editor-content p.is-empty::before {
  content: attr(data-placeholder);
  color: var(--color-placeholder);
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-style: italic;
}

/* Placeholder Styling for Headings */
.editor-content h1.is-empty,
.editor-content h2.is-empty {
  position: relative; /* Ensure relative positioning for absolute pseudo-element */
}

.editor-content h1.is-empty::before,
.editor-content h2.is-empty::before {
  content: 'Start typing here...';
  color: var(--color-placeholder);
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-style: italic;
}

/* Headings */
.editor-content h1,
.editor-content h2 {
  font-weight: bold;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  /* Added position relative to support placeholder positioning */
  position: relative;
}

.editor-content h1 {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.editor-content h2 {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

/* Paragraphs */
.editor-content p {
  margin: 1rem 0;
  position: relative; /* For placeholder positioning */
}

/* Blockquotes */
.editor-content blockquote {
  border-left: 4px solid #d1d5db;
  padding-left: 1rem;
  color: var(--color-muted);
  font-style: italic;
  margin: 1rem 0;
}

/* Lists */
.editor-content ul,
.editor-content ol {
  padding-left: 1.5rem;
  margin: 1rem 0;
}

/* Code Blocks */
.editor-content pre {
  background-color: #f9fafb;
  padding: 16px;
  border-radius: 6px;
  overflow-x: auto;
  font-size: 15px;
  line-height: 1.6;
  white-space: pre-wrap;
  margin: 1rem 0;
}

/* Inline Code */
.editor-content code {
  font-family: var(--font-mono);
  background-color: #f3f4f6;
  padding: 2px 4px;
  border-radius: 4px;
}

/* Images */
.editor-content img {
  max-width: 100%;
  height: auto;
  border-radius: 6px;
  margin: 24px 0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

/* Center-align images */
.editor-content figure {
  display: flex;
  justify-content: center;
  margin: 24px 0;
}

.editor-content figure img {
  max-width: 100%;
  height: auto;
  border-radius: 6px;
}

/* Links */
.editor-content a {
  color: var(--color-primary);
  text-decoration: underline;
}

.editor-content a:hover {
  color: var(--color-primary-hover);
}

/* Horizontal Rules */
.editor-content hr {
  border: none;
  border-top: 1px solid #e5e7eb;
  margin: 2rem 0;
}

/* Responsive Design */

/* Mobile Styles */
@media (max-width: 768px) {
  .note-content {
    padding-left: 0;
    width: 100%;
    display: flex;
    justify-content: center; /* Center the editor content */
  }
  .note-editor-wrapper {
    padding-top: 60px;
    padding-left: var(--padding-mobile);
    padding-right: var(--padding-mobile);
    margin-left: 0; /* Ensure no margin on mobile */
  }

  .note-editor-container {
    max-width: var(--max-width-mobile);
    padding: 0 var(--padding-mobile); /* Reduced padding for mobile */
  }

  .editor-content {
    font-size: var(--font-size-mobile); /* 16px */
    line-height: 1.6;
    padding: 15px; /* Adjusted padding for mobile */
  }

  /* Adjust placeholder on mobile */
  .editor-content p.is-empty::before,
  .editor-content h1.is-empty::before,
  .editor-content h2.is-empty::before {
    left: 12px;
    font-size: var(--font-size-mobile); /* 16px */
  }

  /* No Note Selected Message */
  .no-note-selected {
    font-size: 1.125rem; /* 18px */
    padding: 1.875rem 0.9375rem; /* 30px 15px */
  }
}

/* Tablet Styles */
@media (min-width: 769px) and (max-width: 1024px) {
  .note-content {
    padding-left: 0;
    width: 100%;
    display: flex;
    justify-content: center; /* Center the editor content */
  }

  .note-editor-wrapper {
    padding-top: 70px;
    padding-left: var(--padding-tablet);
    padding-right: var(--padding-tablet);
    margin-left: var(--sidebar-width-tablet); /* Prevent overlapping with sidebar */
  }

  .note-editor-container {
    max-width: var(--max-width-tablet);
    padding: 0 var(--padding-tablet); /* Adjusted padding for tablets */
  }

  .editor-content {
    font-size: var(--font-size-tablet); /* 17px */
    line-height: 1.7;
    padding: 18px; /* Adjusted padding for tablets */
  }

  /* Adjust placeholder on tablets */
  .editor-content p.is-empty::before,
  .editor-content h1.is-empty::before,
  .editor-content h2.is-empty::before {
    left: 14px;
    font-size: var(--font-size-tablet); /* 17px */
  }
}


/* src/components/styling/Sidebar.css */

/* Sidebar Styling */

/* Collections Sidebar */
.collections-sidebar {
    position: fixed;
    top: 60px;
    left: 0;
    bottom: 0;
    width: 250px; /* Adjust as needed */
    background-color: #f5f7fa; /* Light neutral background */
    border-right: 1px solid #ddd; /* Optional border */
    padding: 20px;
    display: flex;
    flex-direction: column;
    overflow-y: auto; /* Allow scrolling within the sidebar */
    overflow-x: hidden;
    box-sizing: border-box;
    z-index: 1000; /* Ensure it stays above main content */
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

/* Custom Scrollbar Styling */

/* WebKit (Chrome, Safari) */
.collections-sidebar::-webkit-scrollbar {
    width: 6px;
}

.collections-sidebar::-webkit-scrollbar-track {
    background: transparent;
}

.collections-sidebar::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
}

.collections-sidebar::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.3);
}

/* Sidebar Header */
.sidebar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.sidebar-header h2 {
    font-size: 22px;
    font-weight: 700;
    color: #2193b0; /* Primary color */
    text-align: left;
    margin: 0;
    position: relative; /* For tooltip positioning */
}

/* Toggle Sidebar Button */
.toggle-sidebar-button {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #2193b0;
    transition: transform 0.3s ease;
}

.toggle-sidebar-button:hover {
    transform: rotate(180deg);
}

/* Rearranging Elements Using Flex Order */
/* Search Container */
.search-container {
    position: relative;
    margin-bottom: 15px;
    order: 1; /* First item */
}

.search-input {
    width: 100%;
    padding: 10px 40px 10px 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
    box-sizing: border-box;
}

.search-input:focus {
    outline: none;
    border-color: #2193b0;
}

.search-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #888888;
    font-size: 18px;
}

/* Information Icon Styles */
.search-info {
    position: absolute;
    right: 35px; /* Positioned to the left of the search icon */
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #888888;
}

.info-icon {
    position: relative;
    font-size: 14px;
    color: #888888;
}

.info-icon:hover {
    color: #2193b0;
}

.info-icon::after {
    content: attr(data-tooltip);
    position: absolute;
    top: 110%; /* Position below the icon */
    left: 50%;
    transform: translateX(-50%);
    background-color: #222;
    color: #fff;
    padding: 8px 10px;
    border-radius: 4px;
    white-space: normal;
    max-width: 220px;
    word-wrap: break-word;
    box-sizing: border-box;
    z-index: 10;
    margin-top: 8px;
    font-size: 13px;
    line-height: 1.4;
    text-align: center;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease;
    pointer-events: none;
}

.info-icon::before {
    content: "";
    position: absolute;
    top: 100%; /* Adjust to position the arrow */
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: #222 transparent transparent transparent;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease;
    pointer-events: none;
}

.info-icon:hover::after,
.info-icon:hover::before {
    opacity: 1;
    visibility: visible;
}

/* Helper Text Below Search Bar */
.search-helper-text {
    font-size: 12px;
    color: #666666;
    margin-bottom: 15px; /* Adjust as needed */
    order: 1; /* Keep with search container */
}

/* Recent Notes Button */
.recent-notes-button {
    background-color: #f0f0f0; /* Light gray background */
    color: #333333; /* Dark text */
    border: none;
    border-radius: 8px;
    padding: 8px 10px; /* Reduced padding */
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s ease, color 0.2s ease;
    margin-bottom: 10px; /* Reduced margin */
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    order: 2; /* Second item */
}

.recent-notes-button:hover {
    background-color: #2193b0; /* Primary color on hover */
    color: #ffffff; /* White text on hover */
}

.recent-notes-icon {
    font-size: 16px;
}

/* Improved Add Folder Button */
.add-folder-button {
    background-color: #2193b0; /* Primary color */
    color: #ffffff; /* White text */
    border: none;
    border-radius: 8px;
    padding: 8px 10px; /* Reduced padding */
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s ease, transform 0.2s ease;
    margin-bottom: 10px; /* Reduced margin */
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    order: 3; /* Third item */
}

.add-folder-button:hover {
    background-color: #197a96; /* Darker primary color on hover */
    transform: translateY(-2px);
}

.add-icon {
    font-size: 16px;
}

/* Divider */
.sidebar-divider {
    border-top: 1px solid #ddd;
    margin: 15px 0;
    order: 4; /* After buttons */
}

/* Folders List */
.folders-list {
    list-style: none;
    padding: 0;
    margin: 0;
    word-wrap: break-word;
    order: 5; /* After divider */
}

/* Folder Item */
.folder-item {
    position: relative;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
}

/* Folder Header */
.folder-header {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    grid-gap: 8px;
    gap: 8px; /* Reduced gap to save space */
    transition: background-color 0.2s ease;
}

/* Removed underline from folder names and note titles */
.folder-name,
.note-title {
    text-decoration: none; /* Remove underline */
}

/* Folder Name */
.folder-name {
    flex-grow: 1;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative; /* For tooltip */
}

.folder-name::after {
    content: attr(title);
    position: absolute;
    top: 110%; /* Position below the folder name */
    left: 50%;
    transform: translateX(-50%);
    background-color: #222;
    color: #fff;
    padding: 8px 10px;
    border-radius: 4px;
    white-space: normal;
    max-width: 220px;
    word-wrap: break-word;
    box-sizing: border-box;
    z-index: 10;
    margin-top: 8px;
    font-size: 13px;
    line-height: 1.4;
    text-align: center;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease;
    pointer-events: none;
}

.folder-name::before {
    content: "";
    position: absolute;
    top: 100%; /* Adjust to position the arrow */
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: #222 transparent transparent transparent;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease;
    pointer-events: none;
}

.folder-name:hover::after,
.folder-name:hover::before {
    opacity: 1;
    visibility: visible;
}

/* Folder Toggle Icon */
.folder-toggle {
    font-size: 14px;
    color: #333333;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-shrink: 0; /* Prevent shrinking */
}

/* Add Note Icon */
.add-note-icon {
    background: none;
    color: #2193b0;
    border: none;
    padding: 0;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-shrink: 0; /* Prevent shrinking */
}

.add-note-icon:hover {
    color: #197a96;
}

/* Folder Dropdown */
.folder-dots {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: #888888;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 50%;
    transition: color 0.2s ease, background-color 0.2s ease, transform 0.2s ease;
}

.folder-dots:hover {
    color: #2193b0;
    background-color: #e0f7fa;
    transform: rotate(90deg);
}

/* Folder Dropdown Container */
.folder-dropdown-container {
    position: absolute;
    top: 100%;
    right: 15px;
    background-color: #ffffff;
    color: #2193b0;
    border: 1px solid #ddd;
    border-radius: 8px;
    z-index: 2000;
    display: none;
    min-width: 120px;
    box-sizing: border-box;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.folder-dropdown-container.show-dropdown {
    display: block;
    opacity: 1;
    transform: translateY(0);
}

.folder-dropdown-container ul {
    list-style: none;
    margin: 0;
    padding: 10px 0;
}

.folder-dropdown-container li {
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
}

.folder-dropdown-container li:hover {
    background-color: #e0f7fa;
}

.dropdown-icon {
    font-size: 14px;
    color: #2193b0;
}

/* Notes List */
.notes-list {
    list-style: none;
    padding: 10px 0;
    margin: 0;
}

/* Note Item */
.note-title-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 5px;
    position: relative;
    width: 100%;
    transition: background-color 0.2s ease;
}

/* Highlight Active Note */
.active-note {
    background-color: #e0f7fa; /* Highlight with secondary color */
}

/* Note Title Styling */
.note-title {
    font-size: 16px;
    color: #333333;
    flex-grow: 1;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative; /* For tooltip */
}

.note-title::after {
    content: attr(title);
    position: absolute;
    top: 110%; /* Position below the note title */
    left: 50%;
    transform: translateX(-50%);
    background-color: #222;
    color: #fff;
    padding: 8px 10px;
    border-radius: 4px;
    white-space: normal;
    max-width: 220px;
    word-wrap: break-word;
    box-sizing: border-box;
    z-index: 10;
    margin-top: 8px;
    font-size: 13px;
    line-height: 1.4;
    text-align: center;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease;
    pointer-events: none;
}

.note-title::before {
    content: "";
    position: absolute;
    top: 100%; /* Adjust to position the arrow */
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: #222 transparent transparent transparent;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease;
    pointer-events: none;
}

.note-title:hover::after,
.note-title:hover::before {
    opacity: 1;
    visibility: visible;
}

/* Note Dropdown */
.note-dots {
    background: none;
    border: none;
    font-size: 14px;
    cursor: pointer;
    color: #888888;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 50%;
    transition: color 0.2s ease, background-color 0.2s ease, transform 0.2s ease;
}

.note-dots:hover {
    color: #2193b0;
    background-color: #e0f7fa;
    transform: rotate(90deg);
}

/* Note Dropdown Container */
.note-dropdown-container {
    position: absolute;
    top: 100%;
    right: 15px;
    background-color: #ffffff;
    color: #2193b0;
    border: 1px solid #ddd;
    border-radius: 8px;
    z-index: 2000;
    display: none;
    min-width: 120px;
    box-sizing: border-box;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.note-dropdown-container.show-dropdown {
    display: block;
    opacity: 1;
    transform: translateY(0);
}

.note-dropdown-container ul {
    list-style: none;
    margin: 0;
    padding: 10px 0;
}

.note-dropdown-container li {
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
}

.note-dropdown-container li:hover {
    background-color: #e0f7fa;
}

.dropdown-icon {
    font-size: 14px;
    color: #2193b0;
}

/* Load More Button */
/* Removed .notes-loading-indicator as it's not used */

/* Divider */
.sidebar-divider {
    border-top: 1px solid #ddd;
    margin: 15px 0;
    order: 4; /* After buttons */
}

/* Load More Button */
.load-more-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.load-more-button {
    background-color: #2193b0;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
    transition: background-color 0.2s ease, transform 0.2s ease;
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
}

.load-more-button:hover {
    background-color: #197a96;
    transform: translateY(-2px);
}

.load-more-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

/* Loading Indicator */
/* Removed .notes-loading-indicator as it's not used */

/* Confirmation Modal Styles */
.confirmation-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}

.confirmation-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.confirmation-modal h2 {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 1.5rem;
}

.confirmation-modal p {
    margin-bottom: 1.5rem;
}

.modal-buttons {
    display: flex;
    justify-content: flex-end;
    grid-gap: 1rem;
    gap: 1rem;
}

.confirm-button,
.cancel-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.confirm-button {
    background-color: #d9534f;
    color: #fff;
}

.cancel-button {
    background-color: #6c757d;
    color: #fff;
}

.confirm-button:hover {
    background-color: #c9302c;
}

.cancel-button:hover {
    background-color: #5a6268;
}

/* Responsive Design for Sidebar */

/* Mobile Styles */
@media (max-width: 768px) {
    .collections-sidebar {
        position: fixed;
        top: 0; /* Align with fixed header */
        left: 0;
        height: 100%;
        width: 250px;
        transform: translateX(-100%);
        transition: transform 0.3s ease-in-out;
        z-index: 1500;
    }

    .collections-sidebar.open {
        transform: translateX(0);
    }

    .collections-sidebar.closed {
        transform: translateX(-100%);
    }

    .sidebar-header h2 {
        margin-top: 60px;
    }

    .folder-dots,
    .note-dots {
        padding: 5px;
        font-size: 18px;
    }

    .toggle-sidebar-button {
        display: block;
    }

    .collections-sidebar.has-overflow::after {
        display: block;
    }

    /* Ensure Recent Notes Button has appropriate order */
    .recent-notes-button {
        order: 2;
    }

    .search-container {
        order: 1;
    }

    .add-folder-button {
        order: 3;
    }

    .sidebar-divider {
        order: 4;
    }

    .folders-list {
        order: 5;
    }
}

/* Tablet Styles */
@media (min-width: 769px) and (max-width: 1024px) {
    .collections-sidebar {
        width: 250px;
        position: fixed;
        top: 60px; /* Align with reduced header height */
        left: 0;
        bottom: 0;
        overflow-y: auto;
        z-index: 1000;
    }

    .sidebar-header h2 {
        font-size: 20px;
    }

    .add-folder-button {
        padding: 8px 10px;
        font-size: 14px;
    }

    .folder-name,
    .note-title {
        font-size: 14px;
    }

    .toggle-sidebar-button {
        display: none;
    }

    .collections-sidebar.has-overflow::after {
        display: block;
    }

    .search-helper-text {
        font-size: 11px; /* Slightly smaller on tablet */
    }

    /* Ensure Recent Notes Button has appropriate order */
    .recent-notes-button {
        order: 2;
    }

    .search-container {
        order: 1;
    }

    .add-folder-button {
        order: 3;
    }

    .sidebar-divider {
        order: 4;
    }

    .folders-list {
        order: 5;
    }
}

/* Desktop Styles */
@media (min-width: 1025px) {
    .collections-sidebar {
        position: fixed;
        top: 60px; /* Align with reduced header height */
        left: 0;
        bottom: 0;
        width: 250px; /* Adjust as needed */
        overflow-y: auto;
        z-index: 1000;
    }

    .toggle-sidebar-button {
        display: none;
    }

    .collections-sidebar.has-overflow::after {
        display: block;
    }

    /* Ensure Recent Notes Button has appropriate order */
    .recent-notes-button {
        order: 2;
    }

    .search-container {
        order: 1;
    }

    .add-folder-button {
        order: 3;
    }

    .sidebar-divider {
        order: 4;
    }

    .folders-list {
        order: 5;
    }
}

/* Folders List */
.folders-list {
    list-style: none;
    padding: 0;
    margin: 0;
    word-wrap: break-word;
}

/* Responsive Flex Ordering */
.collections-sidebar {
    display: flex;
    flex-direction: column;
}

/* src/components/styling/FloatingToolbar.css */

/* General Toolbar Styles */
.floating-toolbar {
  position: fixed;
  display: flex;
  flex-wrap: nowrap; /* Prevents buttons from wrapping */
  overflow: visible; /* Allows dropdowns to be visible outside the toolbar */
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 6px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 10000;
  animation: fadeIn 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  max-width: calc(100% - 16px); /* Ensures toolbar does not exceed screen width */
  box-sizing: border-box; /* Includes padding and border in width */
}

/* Animation for Toolbar Appearance */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px) scale(0.95);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

/* Button Styles within Toolbar */
.floating-toolbar button {
  flex: 0 0 auto; /* Prevents buttons from shrinking */
  background: none;
  border: none;
  cursor: pointer;
  padding: 6px;
  margin: 0 2px;
  font-size: 16px;
  color: #555;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 4px;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.floating-toolbar button:hover {
  background-color: #f0f0f0;
}

.floating-toolbar button.is-active {
  background-color: #e0e0e0;
  color: #000;
}

.floating-toolbar .heading-label {
  margin-left: 4px;
  font-weight: bold;
  font-size: 14px;
}

/* Dropdown Container Styles */
.paragraph-dropdown-container,
.align-dropdown-container,
.color-picker-container,
.bg-color-picker-container {
  position: relative;
}

/* Dropdown Button Styles */
.paragraph-dropdown-container button,
.align-dropdown-container button,
.color-picker-container button,
.bg-color-picker-container button {
  display: flex;
  align-items: center;
}

/* Dropdown Icon Styles */
.dropdown-icon {
  margin-left: 4px;
}

/* Dropdown Popup Styles */
.paragraph-dropdown-popup,
.align-dropdown-popup,
.color-picker-dropdown-popup,
.bg-color-picker-dropdown-popup {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 10001; /* Ensure dropdowns appear above the toolbar */
  display: grid;
  animation: fadeIn 0.3s ease-in-out;
}

/* Specific Dropdown Styles */
.paragraph-dropdown-popup {
  width: 200px;
  grid-template-columns: 1fr;
}

.align-dropdown-popup {
  width: 200px;
  grid-template-columns: 1fr;
}

.color-picker-dropdown-popup,
.bg-color-picker-dropdown-popup {
  width: 200px;
  grid-template-columns: repeat(auto-fill, minmax(28px, 1fr));
  grid-gap: 4px;
  gap: 4px;
}

/* Dropdown Button Styles within Dropdowns */
.paragraph-dropdown-popup button,
.align-dropdown-popup button,
.color-picker-dropdown-popup button,
.bg-color-picker-dropdown-popup button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 6px 8px;
  font-size: 14px;
  color: #555;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.paragraph-dropdown-popup button:hover,
.align-dropdown-popup button:hover,
.color-picker-dropdown-popup button:hover,
.bg-color-picker-dropdown-popup button:hover {
  background-color: #f0f0f0;
}

.paragraph-dropdown-popup button.is-active,
.align-dropdown-popup button.is-active,
.color-picker-dropdown-popup button.is-active,
.bg-color-picker-dropdown-popup button.is-active {
  background-color: #e0e0e0;
  color: #000;
}

.paragraph-dropdown-popup button span,
.align-dropdown-popup button span {
  margin-left: 6px;
}

/* Color Picker Button Styles */
.color-button,
.bg-color-button {
  width: 28px;
  height: 28px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.color-button:hover,
.bg-color-button:hover {
  transform: scale(1.1);
  box-shadow: 0 0 0 2px #555;
}

.color-check-icon,
.bg-color-check-icon {
  position: absolute;
  top: 4px;
  right: 4px;
  color: #fff;
  font-size: 12px;
}

/* Responsive Design for Mobile Devices */
@media (max-width: 768px) {
  .floating-toolbar {
    max-width: 90%; /* Adjust max-width for smaller screens */
  }
}

@media (max-width: 600px) {
  .floating-toolbar {
    padding: 4px;
    max-width: 95%;
  }

  .floating-toolbar button {
    padding: 4px;
    margin: 0 1px;
    font-size: 14px;
  }

  .paragraph-dropdown-popup,
  .align-dropdown-popup,
  .color-picker-dropdown-popup,
  .bg-color-picker-dropdown-popup {
    width: 160px; /* Reduce width for smaller screens */
  }

  .paragraph-dropdown-popup button,
  .align-dropdown-popup button,
  .color-picker-dropdown-popup button,
  .bg-color-picker-dropdown-popup button {
    padding: 4px 6px;
    font-size: 12px;
  }

  .color-button,
  .bg-color-button {
    width: 24px;
    height: 24px;
  }

  .dropdown-icon {
    margin-left: 2px;
  }

  .floating-toolbar .heading-label {
    font-size: 12px;
  }
}

/* Ensuring Dropdowns Appear Above the Toolbar */
.paragraph-dropdown-popup,
.align-dropdown-popup,
.color-picker-dropdown-popup,
.bg-color-picker-dropdown-popup {
  z-index: 10001; /* Ensure dropdowns have a higher z-index */
}

/* Prevent Dropdowns from Being Cut Off */
body {
  overflow: visible;
}

/* src/components/styling/NoteView.css */

/* Full-Screen Background Styling */
.note-view-section {
  padding: 40px 20px; /* Increased padding for better spacing */
  min-height: 100vh; /* Full screen */
  background: linear-gradient(135deg, #f7f9fc, #e2e8f0); /* Subtle gradient background */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Modern font */
}

/* Container Styling */
.noteview-container {
  max-width: 1200px;
  margin: 0 auto;
}

/* Welcome Section Styling */
.welcome-section {
  text-align: center;
  margin-bottom: 40px; /* Increased margin */
}

.welcome-section h2 {
  font-size: 2.5rem; /* Larger font size */
  margin-bottom: 10px;
  color: #2d3748; /* Darker color for better contrast */
}

.welcome-section p {
  font-size: 1.2rem;
  color: #4a5568;
}

/* Recent Notes Section */
.recent-notes-section {
  margin-top: 20px;
}

.recent-notes-grid {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
  justify-content: center;
}

/* Note Card Styling */
.note-card {
  border: 1px solid #d1d5db; /* Light border similar to Google Keep */
  border-radius: 12px; /* Smooth edges */
  width: 280px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.3s ease, border-color 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #333333; /* Dark text for readability */
  overflow: hidden; /* Ensure content doesn't overflow */
}

.note-card:hover {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
  transform: translateY(-4px);
  border-color: #a0aec0; /* Slightly darker border on hover */
}

/* Ensure text is readable against background colors */
.note-card h4 {
  font-size: 1.4rem;
  margin-bottom: 12px;
  color: #1a202c; /* Darker shade */
  font-weight: 600; /* Bold for titles */
}

.note-card p {
  font-size: 1rem;
  color: #333333; /* Darker text color for better contrast */
  margin-bottom: 16px;
  flex-grow: 1;
  overflow: hidden;
  line-height: 1.5;
}

.note-date {
  font-size: 0.9rem;
  color: #555555; /* Slightly lighter for secondary information */
  align-self: flex-end;
  font-style: italic;
}

/* Loading Indicator Styling */
.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}

.dot-spinner {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.dot {
  width: 14px;
  height: 14px;
  background-color: #4a5568;
  border-radius: 50%;
  animation: dot-blink 0.8s infinite alternate;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dot-blink {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}

/* No Recent Notes Styling */
.no-recent-notes {
  text-align: center;
  margin-top: 50px;
}

.no-recent-notes p {
  font-size: 1.1rem;
  color: #4a5568;
}

/* Responsive Design */
@media (max-width: 768px) {
  .note-card {
    width: 100%;
  }

  .welcome-section h2 {
    font-size: 2rem;
  }

  .welcome-section p {
    font-size: 1rem;
  }
}

/* src/components/styling/Breadcrumbs.css */

/* Importing Google Fonts - Poppins and Material Icons */

/* Breadcrumbs Wrapper - Full-Width Gradient Background */
.breadcrumbs-wrapper {
    width: 100%;
    background: linear-gradient(135deg, #f5f7fa, #c3cfe2); /* Gradient background */
    padding: 12px 0; /* Reduced vertical padding for tighter layout */
    position: relative;
    z-index: 1000; /* Ensure it stays above other elements */
}

/* Breadcrumbs Container */
.breadcrumbs {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto; /* Center the breadcrumbs */
    padding: 0 24px; /* Horizontal padding */
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif; /* Consistent font */
    overflow: hidden; /* Prevent overflow into sidebar */
}

/* Breadcrumbs List */
.breadcrumbs ul {
    list-style: none;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto; /* Enable horizontal scroll if needed */
    grid-gap: 12px;
    gap: 12px; /* Increased gap for better spacing */
    padding: 0;
    margin: 0;
    align-items: center;
}

/* Hide scrollbar for cleaner look */
.breadcrumbs ul::-webkit-scrollbar {
    display: none;
}

.breadcrumbs ul {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* Breadcrumb Items */
.breadcrumbs li {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #4a5568;
    white-space: nowrap;
    position: relative;
}

/* Breadcrumb Links */
.breadcrumbs li a {
    color: #2b6cb0;
    text-decoration: none;
    transition: color 0.3s ease, transform 0.3s ease;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.breadcrumbs li a:hover {
    color: #2c5282;
    transform: translateY(-2px); /* Slight upward movement on hover */
}

/* Custom Separator using Material Icons */
.breadcrumbs li::after {
    content: 'arrow_forward'; /* Material Icon name */
    font-family: 'Material Icons';
    font-size: 20px;
    color: #a0aec0;
    margin-left: 12px;
    display: inline-block;
    vertical-align: middle;
}

.breadcrumbs li:last-child::after {
    content: ''; /* Remove separator for the last item */
}

/* Current Page (Non-link) */
.breadcrumbs li span {
    color: #1a202c;
    font-weight: 600;
    display: flex;
    align-items: center;
}

/* Adding an active state indicator (optional) */
.breadcrumbs li span::before {
    content: '';
    width: 8px;
    height: 8px;
    background-color: #2b6cb0;
    border-radius: 50%;
    margin-right: 8px;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
    .breadcrumbs-wrapper {
        padding: 10px 0; /* Adjust vertical padding if needed */
    }

    .breadcrumbs {
        padding: 0 20px;
    }

    .breadcrumbs li {
        font-size: 15px;
    }

    .breadcrumbs li::after {
        font-size: 18px;
        margin-left: 10px;
    }
}

@media (max-width: 768px) {
    .breadcrumbs-wrapper {
        background: linear-gradient(135deg, #e2e8f0, #a0aec0); /* Adjusted gradient */
        padding: 8px 0; /* Adjusted vertical padding */
    }

    .breadcrumbs {
        padding: 0 16px; /* Reduced horizontal padding */
        margin: 10px auto; /* Adjusted top margin */
    }

    .breadcrumbs ul {
        grid-gap: 10px;
        gap: 10px;
    }

    .breadcrumbs li {
        font-size: 14px;
    }

    .breadcrumbs li a {
        font-weight: 400;
    }

    .breadcrumbs li::after {
        font-size: 16px;
        margin-left: 8px;
    }

    .breadcrumbs li span {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .breadcrumbs-wrapper {
        background: linear-gradient(135deg, #cbd5e0, #a0aec0); /* Further adjusted gradient */
        padding: 6px 0; /* Further reduced padding */
    }

    .breadcrumbs {
        padding: 0 12px; /* Further reduced padding */
        margin: 8px auto; /* Further reduced margin */
    }

    .breadcrumbs ul {
        grid-gap: 8px;
        gap: 8px;
    }

    .breadcrumbs li {
        font-size: 13px;
    }

    .breadcrumbs li a {
        font-size: 13px;
    }

    .breadcrumbs li::after {
        font-size: 14px;
        margin-left: 6px;
    }

    .breadcrumbs li span {
        font-size: 13px;
    }
}

/* src/pages/styling/NotFoundPage.css */

/* Main Container */
.not-found-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 40px 20px;
    background-color: #ffffff; /* White background */
    font-family: 'Poppins', sans-serif;
    color: #333333; /* Dark text for readability */
  }
  
  /* Image Container */
  .image-container {
    width: 250px;
    height: 250px;
    margin-bottom: 30px;
    position: relative;
    animation: float 6s ease-in-out infinite;
  }
  
  /* Notebook Man Image */
  .notebook-man {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: transform 0.4s ease, box-shadow 0.4s ease;
    border-radius: 20px;
  }
  
  /* Hover Effects */
  .notebook-man:hover {
    transform: scale(1.05);
    box-shadow: 0 20px 40px rgba(33, 147, 176, 0.3); /* #2193b0 */
  }
  
  /* Floating Animation */
  @keyframes float {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  
  /* Error Title */
  .error-title {
    font-size: 6rem;
    font-weight: 700;
    margin: 0;
    color: #2193b0; /* Primary Blue */
  }
  
  /* Error Subtitle */
  .error-subtitle {
    font-size: 2rem;
    font-weight: 600;
    margin: 10px 0 20px 0;
    color: #555555; /* Dark Gray */
  }
  
  /* Error Message */
  .error-message {
    font-size: 1.2rem;
    margin-bottom: 40px;
    max-width: 500px;
    color: #555555; /* Dark Gray */
  }
  
  /* Call-to-Action Button */
  .cta-button {
    background-color: #2193b0; /* Primary Blue */
    color: #ffffff; /* White Text */
    padding: 15px 30px;
    border: none;
    border-radius: 50px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 10px 20px rgba(33, 147, 176, 0.4); /* #2193b0 */
  }
  
  .cta-button:hover {
    background-color: #6dd5ed; /* Light Blue */
    transform: translateY(-5px);
    box-shadow: 0 15px 25px rgba(33, 147, 176, 0.5); /* #2193b0 */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .image-container {
      width: 200px;
      height: 200px;
      margin-bottom: 20px;
    }
  
    .error-title {
      font-size: 4rem;
    }
  
    .error-subtitle {
      font-size: 1.5rem;
    }
  
    .error-message {
      font-size: 1rem;
      margin-bottom: 30px;
      padding: 0 10px;
    }
  
    .cta-button {
      padding: 12px 25px;
      font-size: 0.9rem;
    }
  }
  
