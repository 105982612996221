/* src/components/styling/Sidebar.css */

/* Sidebar Styling */

/* Collections Sidebar */
.collections-sidebar {
    position: fixed;
    top: 60px;
    left: 0;
    bottom: 0;
    width: 250px; /* Adjust as needed */
    background-color: #f5f7fa; /* Light neutral background */
    border-right: 1px solid #ddd; /* Optional border */
    padding: 20px;
    display: flex;
    flex-direction: column;
    overflow-y: auto; /* Allow scrolling within the sidebar */
    overflow-x: hidden;
    box-sizing: border-box;
    z-index: 1000; /* Ensure it stays above main content */
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

/* Custom Scrollbar Styling */

/* WebKit (Chrome, Safari) */
.collections-sidebar::-webkit-scrollbar {
    width: 6px;
}

.collections-sidebar::-webkit-scrollbar-track {
    background: transparent;
}

.collections-sidebar::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
}

.collections-sidebar::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.3);
}

/* Sidebar Header */
.sidebar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.sidebar-header h2 {
    font-size: 22px;
    font-weight: 700;
    color: #2193b0; /* Primary color */
    text-align: left;
    margin: 0;
    position: relative; /* For tooltip positioning */
}

/* Toggle Sidebar Button */
.toggle-sidebar-button {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #2193b0;
    transition: transform 0.3s ease;
}

.toggle-sidebar-button:hover {
    transform: rotate(180deg);
}

/* Rearranging Elements Using Flex Order */
/* Search Container */
.search-container {
    position: relative;
    margin-bottom: 15px;
    order: 1; /* First item */
}

.search-input {
    width: 100%;
    padding: 10px 40px 10px 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
    box-sizing: border-box;
}

.search-input:focus {
    outline: none;
    border-color: #2193b0;
}

.search-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #888888;
    font-size: 18px;
}

/* Information Icon Styles */
.search-info {
    position: absolute;
    right: 35px; /* Positioned to the left of the search icon */
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #888888;
}

.info-icon {
    position: relative;
    font-size: 14px;
    color: #888888;
}

.info-icon:hover {
    color: #2193b0;
}

.info-icon::after {
    content: attr(data-tooltip);
    position: absolute;
    top: 110%; /* Position below the icon */
    left: 50%;
    transform: translateX(-50%);
    background-color: #222;
    color: #fff;
    padding: 8px 10px;
    border-radius: 4px;
    white-space: normal;
    max-width: 220px;
    word-wrap: break-word;
    box-sizing: border-box;
    z-index: 10;
    margin-top: 8px;
    font-size: 13px;
    line-height: 1.4;
    text-align: center;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease;
    pointer-events: none;
}

.info-icon::before {
    content: "";
    position: absolute;
    top: 100%; /* Adjust to position the arrow */
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: #222 transparent transparent transparent;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease;
    pointer-events: none;
}

.info-icon:hover::after,
.info-icon:hover::before {
    opacity: 1;
    visibility: visible;
}

/* Helper Text Below Search Bar */
.search-helper-text {
    font-size: 12px;
    color: #666666;
    margin-bottom: 15px; /* Adjust as needed */
    order: 1; /* Keep with search container */
}

/* Recent Notes Button */
.recent-notes-button {
    background-color: #f0f0f0; /* Light gray background */
    color: #333333; /* Dark text */
    border: none;
    border-radius: 8px;
    padding: 8px 10px; /* Reduced padding */
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s ease, color 0.2s ease;
    margin-bottom: 10px; /* Reduced margin */
    display: flex;
    align-items: center;
    gap: 5px;
    order: 2; /* Second item */
}

.recent-notes-button:hover {
    background-color: #2193b0; /* Primary color on hover */
    color: #ffffff; /* White text on hover */
}

.recent-notes-icon {
    font-size: 16px;
}

/* Improved Add Folder Button */
.add-folder-button {
    background-color: #2193b0; /* Primary color */
    color: #ffffff; /* White text */
    border: none;
    border-radius: 8px;
    padding: 8px 10px; /* Reduced padding */
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s ease, transform 0.2s ease;
    margin-bottom: 10px; /* Reduced margin */
    display: flex;
    align-items: center;
    gap: 5px;
    order: 3; /* Third item */
}

.add-folder-button:hover {
    background-color: #197a96; /* Darker primary color on hover */
    transform: translateY(-2px);
}

.add-icon {
    font-size: 16px;
}

/* Divider */
.sidebar-divider {
    border-top: 1px solid #ddd;
    margin: 15px 0;
    order: 4; /* After buttons */
}

/* Folders List */
.folders-list {
    list-style: none;
    padding: 0;
    margin: 0;
    word-wrap: break-word;
    order: 5; /* After divider */
}

/* Folder Item */
.folder-item {
    position: relative;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
}

/* Folder Header */
.folder-header {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    gap: 8px; /* Reduced gap to save space */
    transition: background-color 0.2s ease;
}

/* Removed underline from folder names and note titles */
.folder-name,
.note-title {
    text-decoration: none; /* Remove underline */
}

/* Folder Name */
.folder-name {
    flex-grow: 1;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative; /* For tooltip */
}

.folder-name::after {
    content: attr(title);
    position: absolute;
    top: 110%; /* Position below the folder name */
    left: 50%;
    transform: translateX(-50%);
    background-color: #222;
    color: #fff;
    padding: 8px 10px;
    border-radius: 4px;
    white-space: normal;
    max-width: 220px;
    word-wrap: break-word;
    box-sizing: border-box;
    z-index: 10;
    margin-top: 8px;
    font-size: 13px;
    line-height: 1.4;
    text-align: center;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease;
    pointer-events: none;
}

.folder-name::before {
    content: "";
    position: absolute;
    top: 100%; /* Adjust to position the arrow */
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: #222 transparent transparent transparent;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease;
    pointer-events: none;
}

.folder-name:hover::after,
.folder-name:hover::before {
    opacity: 1;
    visibility: visible;
}

/* Folder Toggle Icon */
.folder-toggle {
    font-size: 14px;
    color: #333333;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-shrink: 0; /* Prevent shrinking */
}

/* Add Note Icon */
.add-note-icon {
    background: none;
    color: #2193b0;
    border: none;
    padding: 0;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-shrink: 0; /* Prevent shrinking */
}

.add-note-icon:hover {
    color: #197a96;
}

/* Folder Dropdown */
.folder-dots {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: #888888;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 50%;
    transition: color 0.2s ease, background-color 0.2s ease, transform 0.2s ease;
}

.folder-dots:hover {
    color: #2193b0;
    background-color: #e0f7fa;
    transform: rotate(90deg);
}

/* Folder Dropdown Container */
.folder-dropdown-container {
    position: absolute;
    top: 100%;
    right: 15px;
    background-color: #ffffff;
    color: #2193b0;
    border: 1px solid #ddd;
    border-radius: 8px;
    z-index: 2000;
    display: none;
    min-width: 120px;
    box-sizing: border-box;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.folder-dropdown-container.show-dropdown {
    display: block;
    opacity: 1;
    transform: translateY(0);
}

.folder-dropdown-container ul {
    list-style: none;
    margin: 0;
    padding: 10px 0;
}

.folder-dropdown-container li {
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    gap: 5px;
}

.folder-dropdown-container li:hover {
    background-color: #e0f7fa;
}

.dropdown-icon {
    font-size: 14px;
    color: #2193b0;
}

/* Notes List */
.notes-list {
    list-style: none;
    padding: 10px 0;
    margin: 0;
}

/* Note Item */
.note-title-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 5px;
    position: relative;
    width: 100%;
    transition: background-color 0.2s ease;
}

/* Highlight Active Note */
.active-note {
    background-color: #e0f7fa; /* Highlight with secondary color */
}

/* Note Title Styling */
.note-title {
    font-size: 16px;
    color: #333333;
    flex-grow: 1;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative; /* For tooltip */
}

.note-title::after {
    content: attr(title);
    position: absolute;
    top: 110%; /* Position below the note title */
    left: 50%;
    transform: translateX(-50%);
    background-color: #222;
    color: #fff;
    padding: 8px 10px;
    border-radius: 4px;
    white-space: normal;
    max-width: 220px;
    word-wrap: break-word;
    box-sizing: border-box;
    z-index: 10;
    margin-top: 8px;
    font-size: 13px;
    line-height: 1.4;
    text-align: center;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease;
    pointer-events: none;
}

.note-title::before {
    content: "";
    position: absolute;
    top: 100%; /* Adjust to position the arrow */
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: #222 transparent transparent transparent;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease;
    pointer-events: none;
}

.note-title:hover::after,
.note-title:hover::before {
    opacity: 1;
    visibility: visible;
}

/* Note Dropdown */
.note-dots {
    background: none;
    border: none;
    font-size: 14px;
    cursor: pointer;
    color: #888888;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 50%;
    transition: color 0.2s ease, background-color 0.2s ease, transform 0.2s ease;
}

.note-dots:hover {
    color: #2193b0;
    background-color: #e0f7fa;
    transform: rotate(90deg);
}

/* Note Dropdown Container */
.note-dropdown-container {
    position: absolute;
    top: 100%;
    right: 15px;
    background-color: #ffffff;
    color: #2193b0;
    border: 1px solid #ddd;
    border-radius: 8px;
    z-index: 2000;
    display: none;
    min-width: 120px;
    box-sizing: border-box;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.note-dropdown-container.show-dropdown {
    display: block;
    opacity: 1;
    transform: translateY(0);
}

.note-dropdown-container ul {
    list-style: none;
    margin: 0;
    padding: 10px 0;
}

.note-dropdown-container li {
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    gap: 5px;
}

.note-dropdown-container li:hover {
    background-color: #e0f7fa;
}

.dropdown-icon {
    font-size: 14px;
    color: #2193b0;
}

/* Load More Button */
/* Removed .notes-loading-indicator as it's not used */

/* Divider */
.sidebar-divider {
    border-top: 1px solid #ddd;
    margin: 15px 0;
    order: 4; /* After buttons */
}

/* Load More Button */
.load-more-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.load-more-button {
    background-color: #2193b0;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
    transition: background-color 0.2s ease, transform 0.2s ease;
    display: flex;
    align-items: center;
    gap: 5px;
}

.load-more-button:hover {
    background-color: #197a96;
    transform: translateY(-2px);
}

.load-more-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

/* Loading Indicator */
/* Removed .notes-loading-indicator as it's not used */

/* Confirmation Modal Styles */
.confirmation-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}

.confirmation-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.confirmation-modal h2 {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 1.5rem;
}

.confirmation-modal p {
    margin-bottom: 1.5rem;
}

.modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}

.confirm-button,
.cancel-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.confirm-button {
    background-color: #d9534f;
    color: #fff;
}

.cancel-button {
    background-color: #6c757d;
    color: #fff;
}

.confirm-button:hover {
    background-color: #c9302c;
}

.cancel-button:hover {
    background-color: #5a6268;
}

/* Responsive Design for Sidebar */

/* Mobile Styles */
@media (max-width: 768px) {
    .collections-sidebar {
        position: fixed;
        top: 0; /* Align with fixed header */
        left: 0;
        height: 100%;
        width: 250px;
        transform: translateX(-100%);
        transition: transform 0.3s ease-in-out;
        z-index: 1500;
    }

    .collections-sidebar.open {
        transform: translateX(0);
    }

    .collections-sidebar.closed {
        transform: translateX(-100%);
    }

    .sidebar-header h2 {
        margin-top: 60px;
    }

    .folder-dots,
    .note-dots {
        padding: 5px;
        font-size: 18px;
    }

    .toggle-sidebar-button {
        display: block;
    }

    .collections-sidebar.has-overflow::after {
        display: block;
    }

    /* Ensure Recent Notes Button has appropriate order */
    .recent-notes-button {
        order: 2;
    }

    .search-container {
        order: 1;
    }

    .add-folder-button {
        order: 3;
    }

    .sidebar-divider {
        order: 4;
    }

    .folders-list {
        order: 5;
    }
}

/* Tablet Styles */
@media (min-width: 769px) and (max-width: 1024px) {
    .collections-sidebar {
        width: 250px;
        position: fixed;
        top: 60px; /* Align with reduced header height */
        left: 0;
        bottom: 0;
        overflow-y: auto;
        z-index: 1000;
    }

    .sidebar-header h2 {
        font-size: 20px;
    }

    .add-folder-button {
        padding: 8px 10px;
        font-size: 14px;
    }

    .folder-name,
    .note-title {
        font-size: 14px;
    }

    .toggle-sidebar-button {
        display: none;
    }

    .collections-sidebar.has-overflow::after {
        display: block;
    }

    .search-helper-text {
        font-size: 11px; /* Slightly smaller on tablet */
    }

    /* Ensure Recent Notes Button has appropriate order */
    .recent-notes-button {
        order: 2;
    }

    .search-container {
        order: 1;
    }

    .add-folder-button {
        order: 3;
    }

    .sidebar-divider {
        order: 4;
    }

    .folders-list {
        order: 5;
    }
}

/* Desktop Styles */
@media (min-width: 1025px) {
    .collections-sidebar {
        position: fixed;
        top: 60px; /* Align with reduced header height */
        left: 0;
        bottom: 0;
        width: 250px; /* Adjust as needed */
        overflow-y: auto;
        z-index: 1000;
    }

    .toggle-sidebar-button {
        display: none;
    }

    .collections-sidebar.has-overflow::after {
        display: block;
    }

    /* Ensure Recent Notes Button has appropriate order */
    .recent-notes-button {
        order: 2;
    }

    .search-container {
        order: 1;
    }

    .add-folder-button {
        order: 3;
    }

    .sidebar-divider {
        order: 4;
    }

    .folders-list {
        order: 5;
    }
}

/* Folders List */
.folders-list {
    list-style: none;
    padding: 0;
    margin: 0;
    word-wrap: break-word;
}

/* Responsive Flex Ordering */
.collections-sidebar {
    display: flex;
    flex-direction: column;
}
