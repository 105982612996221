/* src/pages/styling/NotesPage.css */

/* Import Poppins Font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

/* Global Box-Sizing Reset */
*, *::before, *::after {
    box-sizing: border-box;
}

/* Global Styles for the Application */
body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    color: #4D4D4D;
    background-color: #FFFFFF;
    overflow-x: hidden;
}

/* Utility Classes */
.hidden {
    display: none;
}

.visible {
    display: block;
}

/* Header Styling */
.app-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #f1fcff;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1500;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    flex-wrap: wrap;
    gap: 8px;
}

/* Header Left Section */
.header-left {
    display: flex;
    align-items: center;
    flex: 1;
    min-width: 0;
}

/* Header Title */
.app-header h1 {
    text-align: left;
    color: #2193b0;
    font-size: 24px;
    font-weight: 700;
    margin: 0;
    flex-shrink: 0;
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 50px;
}

/* Header Center Section (Buttons) */
.header-center {
    display: none;
    align-items: center;
    justify-content: center;
    flex: 1;
    min-width: 0;
    gap: 10px;
}

/* Show Header Buttons When a Note is Open */
.app-header.note-open .header-center {
    display: flex;
}

/* Header Buttons Styling */
.header-button {
    background-color: #FFFFFF;
    color: #2193b0;
    border: 1px solid #2193b0;
    padding: 6px 10px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 12px;
    transition: background-color 0.2s, color 0.2s;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    white-space: nowrap;
}

.header-button:hover {
    background-color: #2193b0;
    color: #FFFFFF;
}

/* AI Notes Button */
.ai-notes-button {
    background-color: #FF5722;
    color: #FFFFFF;
    border: none;
    padding: 10px 16px;
    border-radius: 25px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    transition: background-color 0.3s, transform 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
    animation: pulse 3s infinite;
}

.ai-notes-button:hover {
    background-color: #E64A19;
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.ai-notes-button:active {
    background-color: #D84315;
    transform: translateY(0);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.ai-notes-icon {
    width: 20px;
    height: 20px;
    transition: transform 0.3s;
}

.ai-notes-text {
    font-size: 14px;
    color: #FFFFFF;
    font-weight: 600;
}

/* Upload Image Container */
.upload-image-container {
    display: flex;
    align-items: center;
    gap: 8px;
}

.upload-image-container label.header-button {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 6px 10px;
}

/* Profile Section */
.profile-section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    flex: 1;
    min-width: 0;
    margin-left: auto;
}

/* Profile Icon */
.profile-icon {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #2193b0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s;
}

.profile-icon:hover {
    background-color: #1a78a0;
}

.profile-picture {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.profile-placeholder {
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
}

/* Profile Dropdown Menu */
.profile-dropdown {
    position: absolute;
    top: 45px;
    right: 0;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    z-index: 2000;
    min-width: 140px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.profile-dropdown ul {
    list-style: none;
    margin: 0;
    padding: 8px 0;
}

.profile-dropdown li {
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.profile-dropdown li:hover {
    background-color: #f1f1f1;
}

/* Sidebar Toggle Button (Hamburger Icon) */
.sidebar-toggle-button {
    position: fixed;
    top: 12px;
    left: 12px;
    background-color: #ffffff;
    border: none;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    font-size: 20px;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 1600;
    transition: transform 0.3s, box-shadow 0.3s;
}

.sidebar-toggle-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* Adjust Main Container to Account for Fixed Header and Breadcrumbs */
.notes-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
    background-color: #FFFFFF;
    padding-top: 60px;
    overflow-x: hidden;
}

/* Breadcrumbs Wrapper */
.breadcrumbs-wrapper {
    position: fixed;
    top: 60px;
    left: 250px;
    right: 0;
    z-index: 999;
    padding: 12px 0;
    background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
}

/* Tablet Adjustments */
@media (max-width: 1024px) and (min-width: 769px) {
    .breadcrumbs-wrapper {
        left: 200px;
        width: calc(100% - 200px);
    }
}

/* No Note Selected */
.no-note-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100vh - 60px);
    padding: 15px;
    box-sizing: border-box;
}

.no-note-selected {
    text-align: center;
    color: #555555;
}

.no-note-selected p {
    font-size: 16px;
    margin-top: 10px;
}

.no-note-selected h2 {
    font-size: 22px;
    margin-bottom: 8px;
    margin-top: -20px;
    color: #2d3748;
}

/* Note View Content Area */
.note-view-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    background-color: #FFFFFF;
    padding: 15px;
    overflow-y: auto;
}

/* Editor Content Area */
.editor-content {
    width: 100%;
    max-width: 750px;
    flex-grow: 1;
    outline: none;
    font-size: 16px;
    line-height: 1.6;
    color: #333333;
    white-space: pre-wrap;
    word-wrap: break-word;
    position: relative;
    overflow-y: auto;
}

/* Toolbar Styling */
.toolbar {
    width: 100%;
    max-width: 750px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    background-color: #f8f9fa;
    border-radius: 8px;
    flex-wrap: wrap;
    margin-bottom: 8px;
    box-sizing: border-box;
}

.toolbar button,
.toolbar select {
    background-color: #FFFFFF;
    color: #4D4D4D;
    border: 1px solid #ddd;
    padding: 6px 10px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 12px;
    transition: background-color 0.2s, transform 0.1s, color 0.2s;
    flex: 0 0 auto;
}

.toolbar button:hover,
.toolbar select:hover {
    background-color: #f0f0f0;
}

.toolbar button.active {
    background-color: #2193b0;
    color: #FFFFFF;
    border-color: #2193b0;
}

.toolbar .text-color-select {
    padding: 6px 10px;
    border-radius: 6px;
    border: 1px solid #ddd;
    background-color: #ffffff;
    color: #4D4D4D;
    cursor: pointer;
}

.toolbar .text-color-select:hover {
    border-color: #2193b0;
}

.toolbar .image-upload-options {
    display: flex;
    gap: 8px;
}

.toolbar .image-upload-label {
    background-color: #FFFFFF;
    color: #2193b0;
    border: 1px solid #2193b0;
    padding: 6px 10px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 12px;
    transition: background-color 0.2s, color 0.2s;
}

.toolbar .image-upload-label:hover {
    background-color: #2193b0;
    color: #FFFFFF;
}

/* Editor Buttons (Close Note) */
.editor-buttons {
    width: 100%;
    max-width: 750px;
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
}

.close-button {
    padding: 8px 16px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-weight: 600;
    background-color: #f8f9fa;
    color: #333333;
    transition: background-color 0.3s, transform 0.3s;
}

.close-button:hover {
    background-color: #e9ecef;
    transform: translateY(-2px);
}

/* Modal Styling */
.ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.5) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

/* Actual Modal Content */
.modal-content {
    background-color: #ffffff;
    padding: 25px;
    border-radius: 20px;
    width: 90%;
    max-width: 380px;
    position: relative;
    transform: scale(1);
    animation: modalFadeIn 0.3s ease-in-out;
}

@keyframes modalFadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

.modal-content h2 {
    margin-top: 0;
    color: #2193b0;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 16px;
}

.folder-modal-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.folder-name-input {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 14px;
    transition: border-color 0.3s;
    width: 100%;
    box-sizing: border-box;
}

.folder-name-input:focus {
    border-color: #2193b0;
    outline: none;
}

.modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 16px;
}

/* Editor Placeholder */
.editor-content.is-empty::before {
    content: attr(data-placeholder);
    color: #aaa;
    position: absolute;
    pointer-events: none;
    left: 15px;
    top: 15px;
    font-size: 16px;
    line-height: 1.6;
}

/* Placeholder for Title Input */
.title-input.is-empty::before {
    content: attr(data-placeholder);
    color: #aaa;
    position: absolute;
    pointer-events: none;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    line-height: 1.2;
}

/* Responsive Design */

/* Mobile Styles */
@media (max-width: 768px) {
    /* Adjust Main Container Padding for Small Screens */
    .notes-container {
        padding-top: 80px;
    }

    .no-note-wrapper {
        height: calc(100vh - 80px);
    }

    /* Show Hamburger Button */
    .sidebar-toggle-button {
        display: flex;
    }

    /* Prevent Header Title Overlap */
    .app-header {
        flex-wrap: nowrap;
    }

    .app-header h1 {
        flex: 1;
        margin-right: 50px;
    }

    /* Adjust AI Notes Button on Mobile */
    .ai-notes-button {
        padding: 8px 12px;
        font-size: 12px;
        gap: 6px;
    }

    .ai-notes-text {
        display: none;
    }

    /* Adjust Image Insertion Button on Mobile */
    .upload-image-container label.header-button {
        padding: 6px 8px;
        font-size: 12px;
        gap: 4px;
    }

    .upload-image-container label.header-button span {
        display: none;
    }

    /* Adjust Breadcrumbs for Mobile */
    .breadcrumbs-wrapper {
        left: 0;
        top: 50px;
        padding: 8px 0;
        width: 100%;
    }

    .breadcrumbs {
        padding: 0 16px;
        margin: 10px auto;
    }

    .breadcrumbs ul {
        gap: 10px;
    }

    .breadcrumbs li {
        font-size: 14px;
    }

    .breadcrumbs li a {
        font-weight: 400;
    }

    .breadcrumbs li::after {
        font-size: 16px;
        margin-left: 8px;
    }

    .breadcrumbs li span {
        font-size: 14px;
    }

    /* Further reduce padding and margins */
    .recent-notes-section {
        margin-top: 8px;
    }

    .recent-notes-grid {
        gap: 8px;
    }

    .welcome-section h2 {
        font-size: 20px;
        margin-top: 10px;
        margin-bottom: 6px;
    }

    .note-card {
        padding: 10px;
    }

    /* Adjust Toolbar */
    .toolbar {
        padding: 6px;
        gap: 6px;
    }

    .toolbar button,
    .toolbar select {
        padding: 5px 8px;
        font-size: 12px;
    }

    /* Adjust Editor Buttons */
    .editor-buttons {
        flex-direction: column;
        align-items: flex-end;
        max-width: 750px;
    }

    .close-button {
        width: 100%;
        padding: 8px 14px;
    }
}

/* Very Small Screens */
@media (max-width: 480px) {
    /* Further Adjust Main Container Padding */
    .notes-container {
        padding-top: 70px;
    }

    .no-note-wrapper {
        height: calc(100vh - 70px);
    }

    /* Adjust Header Title */
    .app-header h1 {
        margin-right: 40px;
        font-size: 20px;
    }

    /* Adjust AI Notes Button */
    .ai-notes-button {
        padding: 6px 10px;
        font-size: 11px;
        gap: 4px;
    }

    /* Adjust Breadcrumbs */
    .breadcrumbs-wrapper {
        padding: 6px 0;
    }

    .breadcrumbs {
        padding: 0 12px;
        margin: 8px auto;
    }

    .breadcrumbs ul {
        gap: 8px;
    }

    .breadcrumbs li,
    .breadcrumbs li a,
    .breadcrumbs li span {
        font-size: 13px;
    }

    .breadcrumbs li::after {
        font-size: 14px;
        margin-left: 6px;
    }
}

/* Tablet Styles */
@media (min-width: 769px) and (max-width: 1024px) {
    .breadcrumbs-wrapper {
        left: 200px;
        width: calc(100% - 200px);
    }

    .app-header h1 {
        margin-right: 50px;
    }

    .upload-image-container label.header-button span {
        display: inline;
    }
}

/* Offline Alert Styling */
.offline-alert {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #c53d3d;
    color: white;
    text-align: center;
    padding: 8px;
    z-index: 2000;
    font-weight: bold;
}

.offline-alert p {
    margin: 0;
    font-size: 14px;
}

/* Save Indicator Styling */
.save-indicator {
    position: fixed;
    bottom: 16px;
    right: 16px;
    background-color: #4caf50;
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    z-index: 2000;
    font-size: 10px;
    transition: background-color 0.3s, opacity 0.3s;
    opacity: 1;
}

.save-indicator.saving {
    background-color: #ff9800;
}

/* Loading Indicators */
.notes-loading-indicator,
.folders-loading-indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    font-size: 14px;
    color: #555;
}

/* Load More Button */
.load-more-button {
    padding: 8px 16px;
    margin: 16px auto;
    display: block;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.load-more-button:hover {
    background-color: #0056b3;
}

/* Disabled Save Button */
.save-button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
}

/* Cancel Button */
.cancel-button {
    padding: 8px 16px;
    margin-left: 8px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.cancel-button:hover {
    background-color: #c82333;
}

/* Error Message Styling */
.error-message {
    text-align: center;
    margin-top: 40px;
    color: #e53e3e;
    font-size: 14px;
}

/* Note View Specific Styles */
.note-view-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    background-color: #FFFFFF;
    padding: 15px;
    overflow-y: auto;
}

/* Note Content Area */
.note-view-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    background-color: #FFFFFF;
    padding: 15px;
    overflow-y: auto;
}

/* Note Editor Specific Styles */
.note-editor {
    width: 100%;
    max-width: 750px;
    margin: 0 auto;
    padding: 10px;
}

/* Recent Notes Presentation */
.recent-notes-section {
    margin-top: 10px;
}

.recent-notes-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: center;
}

/* Note Cards */
.note-card {
    margin: 0;
    padding: 12px;
    border: 1px solid #d1d5db;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.3s;
}

.note-card:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    transform: translateY(-4px);
}

/* Recent Notes Title */
.welcome-section h2 {
    font-size: 22px;
    margin-bottom: 8px;
    margin-top: -20px;
    color: #2d3748;
}

/* Pulse Animation */
@keyframes pulse {
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(255, 87, 34, 0.7);
    }
    70% {
        transform: scale(1.05);
        box-shadow: 0 0 0 10px rgba(255, 87, 34, 0);
    }
    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(255, 87, 34, 0);
    }
}

/* Footer */
.footer {
    margin-top: 0;
}
