/* src/components/styling/FloatingToolbar.css */

/* General Toolbar Styles */
.floating-toolbar {
  position: fixed;
  display: flex;
  flex-wrap: nowrap; /* Prevents buttons from wrapping */
  overflow: visible; /* Allows dropdowns to be visible outside the toolbar */
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 6px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 10000;
  animation: fadeIn 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  max-width: calc(100% - 16px); /* Ensures toolbar does not exceed screen width */
  box-sizing: border-box; /* Includes padding and border in width */
}

/* Animation for Toolbar Appearance */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px) scale(0.95);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

/* Button Styles within Toolbar */
.floating-toolbar button {
  flex: 0 0 auto; /* Prevents buttons from shrinking */
  background: none;
  border: none;
  cursor: pointer;
  padding: 6px;
  margin: 0 2px;
  font-size: 16px;
  color: #555;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 4px;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.floating-toolbar button:hover {
  background-color: #f0f0f0;
}

.floating-toolbar button.is-active {
  background-color: #e0e0e0;
  color: #000;
}

.floating-toolbar .heading-label {
  margin-left: 4px;
  font-weight: bold;
  font-size: 14px;
}

/* Dropdown Container Styles */
.paragraph-dropdown-container,
.align-dropdown-container,
.color-picker-container,
.bg-color-picker-container {
  position: relative;
}

/* Dropdown Button Styles */
.paragraph-dropdown-container button,
.align-dropdown-container button,
.color-picker-container button,
.bg-color-picker-container button {
  display: flex;
  align-items: center;
}

/* Dropdown Icon Styles */
.dropdown-icon {
  margin-left: 4px;
}

/* Dropdown Popup Styles */
.paragraph-dropdown-popup,
.align-dropdown-popup,
.color-picker-dropdown-popup,
.bg-color-picker-dropdown-popup {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 10001; /* Ensure dropdowns appear above the toolbar */
  display: grid;
  animation: fadeIn 0.3s ease-in-out;
}

/* Specific Dropdown Styles */
.paragraph-dropdown-popup {
  width: 200px;
  grid-template-columns: 1fr;
}

.align-dropdown-popup {
  width: 200px;
  grid-template-columns: 1fr;
}

.color-picker-dropdown-popup,
.bg-color-picker-dropdown-popup {
  width: 200px;
  grid-template-columns: repeat(auto-fill, minmax(28px, 1fr));
  gap: 4px;
}

/* Dropdown Button Styles within Dropdowns */
.paragraph-dropdown-popup button,
.align-dropdown-popup button,
.color-picker-dropdown-popup button,
.bg-color-picker-dropdown-popup button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 6px 8px;
  font-size: 14px;
  color: #555;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.paragraph-dropdown-popup button:hover,
.align-dropdown-popup button:hover,
.color-picker-dropdown-popup button:hover,
.bg-color-picker-dropdown-popup button:hover {
  background-color: #f0f0f0;
}

.paragraph-dropdown-popup button.is-active,
.align-dropdown-popup button.is-active,
.color-picker-dropdown-popup button.is-active,
.bg-color-picker-dropdown-popup button.is-active {
  background-color: #e0e0e0;
  color: #000;
}

.paragraph-dropdown-popup button span,
.align-dropdown-popup button span {
  margin-left: 6px;
}

/* Color Picker Button Styles */
.color-button,
.bg-color-button {
  width: 28px;
  height: 28px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.color-button:hover,
.bg-color-button:hover {
  transform: scale(1.1);
  box-shadow: 0 0 0 2px #555;
}

.color-check-icon,
.bg-color-check-icon {
  position: absolute;
  top: 4px;
  right: 4px;
  color: #fff;
  font-size: 12px;
}

/* Responsive Design for Mobile Devices */
@media (max-width: 768px) {
  .floating-toolbar {
    max-width: 90%; /* Adjust max-width for smaller screens */
  }
}

@media (max-width: 600px) {
  .floating-toolbar {
    padding: 4px;
    max-width: 95%;
  }

  .floating-toolbar button {
    padding: 4px;
    margin: 0 1px;
    font-size: 14px;
  }

  .paragraph-dropdown-popup,
  .align-dropdown-popup,
  .color-picker-dropdown-popup,
  .bg-color-picker-dropdown-popup {
    width: 160px; /* Reduce width for smaller screens */
  }

  .paragraph-dropdown-popup button,
  .align-dropdown-popup button,
  .color-picker-dropdown-popup button,
  .bg-color-picker-dropdown-popup button {
    padding: 4px 6px;
    font-size: 12px;
  }

  .color-button,
  .bg-color-button {
    width: 24px;
    height: 24px;
  }

  .dropdown-icon {
    margin-left: 2px;
  }

  .floating-toolbar .heading-label {
    font-size: 12px;
  }
}

/* Ensuring Dropdowns Appear Above the Toolbar */
.paragraph-dropdown-popup,
.align-dropdown-popup,
.color-picker-dropdown-popup,
.bg-color-picker-dropdown-popup {
  z-index: 10001; /* Ensure dropdowns have a higher z-index */
}

/* Prevent Dropdowns from Being Cut Off */
body {
  overflow: visible;
}
