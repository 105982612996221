/* src/components/styling/NoteEditor.css */

/* CSS Variables for Maintainability */
:root {
  --color-primary: #3b82f6;
  --color-primary-hover: #2563eb;
  --color-placeholder: #9ca3af;
  --color-text: #1f2937;
  --color-muted: #6b7280;
  --color-bg: #ffffff;
  --font-base: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  --font-mono: 'Source Code Pro', monospace;
  --max-width-desktop: 1200px;
  --max-width-tablet: 900px;
  --max-width-mobile: 100%;
  --sidebar-width-tablet: 250px; /* Adjust based on your sidebar's width */
  --padding-desktop: 0px;
  --padding-tablet: 20px;
  --padding-mobile: 0px;
  --font-size-base: 1.01rem; /* 18px */
  --font-size-mobile: 1rem; /* 16px */
  --font-size-tablet: 1.0625rem; /* 17px */
}

/* Wrapper to fill the full viewport height and center content */
.note-editor-wrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: flex-start; /* Align items to the top */
  padding-top: 0px; /* Top padding for spacing */
  box-sizing: border-box;
  background-color: var(--color-bg); /* White background to blend with content */
  margin-left: 0; /* Default margin */
  transition: margin-left 0.3s ease; /* Smooth transition for margin changes */
  margin-left: 140px;

}

/* Adjust wrapper to account for sidebar on tablets */
@media (min-width: 769px) and (max-width: 1024px) {
  .note-editor-wrapper {
    margin-left: var(--sidebar-width-tablet); /* Prevent overlapping with sidebar */
    padding-left: var(--padding-tablet);
    padding-right: var(--padding-tablet);
    padding-top: 70px;
  }
}

/* Container to constrain the editor's width */
.note-editor-container {
  width: 100%;
  max-width: var(--max-width-desktop); /* Maximum width of the container */
  padding: 0 var(--padding-desktop); /* Horizontal padding */
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: center; /* Center the content within the container */
}

/* Message when no note is selected */
.no-note-selected {
  text-align: center;
  color: var(--color-muted);
  padding: 3.75rem 1.25rem; /* 60px 20px */
  font-size: 1.25rem; /* 20px */
}

/* Note Content */
.note-content {
  width: 100%;
  display: flex;
  justify-content: center; /* Center the editor content */
}

/* Editor Container */
.editor-container {
  width: 100%;
  max-width: 800px; /* Set a maximum width for the editor */
  position: relative;
}

/* Editor Content */
.editor-content {
  width: 100%;
  max-width: 800px; /* Ensure the content doesn't exceed the container */
  margin: 0 auto; /* Center the editor content */
  min-height: 600px;
  font-size: var(--font-size-base); /* 18px */
  line-height: 1.8;
  color: var(--color-text);
  font-family: var(--font-base);
  overflow-wrap: break-word; /* Replaced deprecated word-wrap */
  padding: 20px; /* Added padding for better spacing */
  box-sizing: border-box;
  position: relative;
}

/* Placeholder Styling for Paragraphs */
.editor-content p.is-empty {
  position: relative;
}

.editor-content p.is-empty::before {
  content: attr(data-placeholder);
  color: var(--color-placeholder);
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-style: italic;
}

/* Placeholder Styling for Headings */
.editor-content h1.is-empty,
.editor-content h2.is-empty {
  position: relative; /* Ensure relative positioning for absolute pseudo-element */
}

.editor-content h1.is-empty::before,
.editor-content h2.is-empty::before {
  content: 'Start typing here...';
  color: var(--color-placeholder);
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-style: italic;
}

/* Headings */
.editor-content h1,
.editor-content h2 {
  font-weight: bold;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  /* Added position relative to support placeholder positioning */
  position: relative;
}

.editor-content h1 {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.editor-content h2 {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

/* Paragraphs */
.editor-content p {
  margin: 1rem 0;
  position: relative; /* For placeholder positioning */
}

/* Blockquotes */
.editor-content blockquote {
  border-left: 4px solid #d1d5db;
  padding-left: 1rem;
  color: var(--color-muted);
  font-style: italic;
  margin: 1rem 0;
}

/* Lists */
.editor-content ul,
.editor-content ol {
  padding-left: 1.5rem;
  margin: 1rem 0;
}

/* Code Blocks */
.editor-content pre {
  background-color: #f9fafb;
  padding: 16px;
  border-radius: 6px;
  overflow-x: auto;
  font-size: 15px;
  line-height: 1.6;
  white-space: pre-wrap;
  margin: 1rem 0;
}

/* Inline Code */
.editor-content code {
  font-family: var(--font-mono);
  background-color: #f3f4f6;
  padding: 2px 4px;
  border-radius: 4px;
}

/* Images */
.editor-content img {
  max-width: 100%;
  height: auto;
  border-radius: 6px;
  margin: 24px 0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

/* Center-align images */
.editor-content figure {
  display: flex;
  justify-content: center;
  margin: 24px 0;
}

.editor-content figure img {
  max-width: 100%;
  height: auto;
  border-radius: 6px;
}

/* Links */
.editor-content a {
  color: var(--color-primary);
  text-decoration: underline;
}

.editor-content a:hover {
  color: var(--color-primary-hover);
}

/* Horizontal Rules */
.editor-content hr {
  border: none;
  border-top: 1px solid #e5e7eb;
  margin: 2rem 0;
}

/* Responsive Design */

/* Mobile Styles */
@media (max-width: 768px) {
  .note-content {
    padding-left: 0;
    width: 100%;
    display: flex;
    justify-content: center; /* Center the editor content */
  }
  .note-editor-wrapper {
    padding-top: 60px;
    padding-left: var(--padding-mobile);
    padding-right: var(--padding-mobile);
    margin-left: 0; /* Ensure no margin on mobile */
  }

  .note-editor-container {
    max-width: var(--max-width-mobile);
    padding: 0 var(--padding-mobile); /* Reduced padding for mobile */
  }

  .editor-content {
    font-size: var(--font-size-mobile); /* 16px */
    line-height: 1.6;
    padding: 15px; /* Adjusted padding for mobile */
  }

  /* Adjust placeholder on mobile */
  .editor-content p.is-empty::before,
  .editor-content h1.is-empty::before,
  .editor-content h2.is-empty::before {
    left: 12px;
    font-size: var(--font-size-mobile); /* 16px */
  }

  /* No Note Selected Message */
  .no-note-selected {
    font-size: 1.125rem; /* 18px */
    padding: 1.875rem 0.9375rem; /* 30px 15px */
  }
}

/* Tablet Styles */
@media (min-width: 769px) and (max-width: 1024px) {
  .note-content {
    padding-left: 0;
    width: 100%;
    display: flex;
    justify-content: center; /* Center the editor content */
  }

  .note-editor-wrapper {
    padding-top: 70px;
    padding-left: var(--padding-tablet);
    padding-right: var(--padding-tablet);
    margin-left: var(--sidebar-width-tablet); /* Prevent overlapping with sidebar */
  }

  .note-editor-container {
    max-width: var(--max-width-tablet);
    padding: 0 var(--padding-tablet); /* Adjusted padding for tablets */
  }

  .editor-content {
    font-size: var(--font-size-tablet); /* 17px */
    line-height: 1.7;
    padding: 18px; /* Adjusted padding for tablets */
  }

  /* Adjust placeholder on tablets */
  .editor-content p.is-empty::before,
  .editor-content h1.is-empty::before,
  .editor-content h2.is-empty::before {
    left: 14px;
    font-size: var(--font-size-tablet); /* 17px */
  }
}

