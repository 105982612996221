/* frontend/src/pages/ForgotPasswordPage.css */

/* Import Poppins Font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

/* Global Styles */
body {
  font-family: 'Poppins', sans-serif;
  background-color: #f1fcff;
  margin: 0;
  padding: 0;
}

/* Forgot Password Page Styles */
.forgot-password-page {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
}

.forgot-password-container {
  background-color: #ffffff;
  padding: 40px 30px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.forgot-password-title {
  font-size: 28px;
  color: #2193b0;
  margin-bottom: 30px;
}

.forgot-password-form {
  display: flex;
  flex-direction: column;
}

.forgot-password-input {
  padding: 14px 18px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 20px;
  transition: border-color 0.3s;
}

.forgot-password-input:focus {
  border-color: #2193b0;
  outline: none;
}

.forgot-password-button {
  padding: 14px 18px;
  font-size: 16px;
  background-color: #2193b0;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s;
}

.forgot-password-button:hover {
  background-color: #176b85;
}

.forgot-password-button:disabled {
  background-color: #a0a0a0;
  cursor: not-allowed;
}

.forgot-password-message {
  margin-top: 20px;
  font-size: 14px;
  text-align: center;
}

.forgot-password-message.success {
  color: #4caf50;
}

.forgot-password-message.error {
  color: #f44336;
}

.forgot-password-links {
  margin-top: 30px;
}

.forgot-password-links a {
  color: #2193b0;
  text-decoration: none;
  font-size: 14px;
}

.forgot-password-links a:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 480px) {
  .forgot-password-container {
    padding: 30px 20px;
  }

  .forgot-password-title {
    font-size: 24px;
  }

  .forgot-password-input,
  .forgot-password-button {
    font-size: 14px;
    padding: 12px 16px;
  }

  .forgot-password-message {
    font-size: 12px;
  }
}
